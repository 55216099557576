import React, { useState } from 'react'
import Card from '@mui/material/Card'
import { useParams, useLocation } from 'react-router-dom'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import Papa from 'papaparse'
import axios from 'axios'

const GoHighLevel = () => {
  const [csvFile, setCsvFile] = useState(null) // Store the CSV file
  const [fileName, setFileName] = useState('') // Store the file name
  const { locationId, companyId, app_id } = useParams()
  const { search } = useLocation()
  const type = new URLSearchParams(search).get('type')

  // Handle file selection and store the file
  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      setCsvFile(file)
      setFileName(file.name) // Update state with the file name
    }
  }

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault() // Prevent page refresh

    if (csvFile) {
      Papa.parse(csvFile, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          console.log('CSV Data:', result.data)
          // Send parsed data to the API after parsing
          sendCsvDataToAPI(result.data)
        },
        error: (error) => {
          console.error('Error parsing CSV:', error)
        },
      })
    } else {
      console.error('No file selected')
    }
  }

  // Function to send parsed CSV data to the API
  const sendCsvDataToAPI = async (data) => {
    try {
      const response = await axios.post(
        'http://localhost:8082/v1/csvfile/create-location/6698f92a21c253137fa85644',
        {
          locationId,
          companyId,
          app_id,
          type,
          csvData: data, // Sending the parsed CSV data
        }
      )
      console.log('API Response:', response.data)
    } catch (error) {
      console.error('Error sending data to API:', error)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Card
        sx={{
          width: '22%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <MDTypography variant="h4" gutterBottom>
          Upload CSV File
        </MDTypography>

        {/* Form with onSubmit */}
        <MDBox component="form" sx={{ mt: 2 }} onSubmit={handleSubmit}>
          <label
            htmlFor="file-upload"
            style={{
              display: 'inline-block',
              padding: '10px 20px',
              backgroundColor: '#2196F3',
              color: '#fff',
              borderRadius: '9px',
              cursor: 'pointer',
              fontSize: '1rem',
              width: '100%',
              marginTop: '2rem',
            }}
          >
            {fileName ? (
              <>{fileName}</>
            ) : (
              <>
                <>Choose File</>
              </>
            )}
          </label>
          <input
            id="file-upload"
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />

          {/* Submit button */}
          <button
            type="submit"
            style={{
              display: 'inline-block',
              padding: '10px 20px',
              backgroundColor: '#4CAF50',
              color: '#fff',
              borderRadius: '9px',
              cursor: 'pointer',
              fontSize: '1rem',
              width: '100%',
              marginTop: '1rem',
              border: 'none',
            }}
          >
            Upload CSV
          </button>
        </MDBox>
      </Card>
    </div>
  )
}

export default GoHighLevel

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react

* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com


* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useLocation, useParams } from 'react-router-dom'
// @mui material components
import Card from '@mui/material/Card'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import { useState } from 'react'
import { TextField, Typography } from '@mui/material'

function GoHighLevel() {
  const [clientId, setClientId] = useState('')
  const [clientsecret, setClientSecret] = useState('')
  const [secretToken, setSecretToken] = useState('')
  const { search } = useLocation()
  const type = new URLSearchParams(search).get('type')
  const { companyId, app_id, locationId } = useParams()

  const auth_url = `https://drchrono.com/o/authorize/?redirect_uri=https://app.alltheapps.io/integrations/drchrono&response_type=code&client_id=${clientId}&state=${locationId},${companyId},${app_id},${type},${clientId},${clientsecret},${secretToken}`

  const handleConnect = (e) => {
    e.preventDefault()
    window.open(auth_url, '_blank')
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundSize: 'cover',
      }}
    >
      <Card
        sx={{
          width: '35%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <MDTypography variant="h4" gutterBottom style={{ color: 'black' }}>
          Connect with DrChrono
        </MDTypography>

        <MDBox component="form" onSubmit={handleConnect} sx={{ mt: 2 }}>
          <TextField
            type="text"
            label="DRCHRONO_CLIENT_ID"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
            required
          />

          <TextField
            type="text"
            label="DRCHRONO_CLIENT_SECRET"
            value={clientsecret}
            onChange={(e) => setClientSecret(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
            required
          />

          <TextField
            type="text"
            label="WEBHOOK_SECRET_TOKEN"
            value={secretToken}
            onChange={(e) => setSecretToken(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
            required
          />
          <Typography style={{ textAlign: 'left', fontSize: '0.9rem', color: 'red' }}>
            Important Note:
          </Typography>
          <Typography style={{ fontSize: '0.9rem', textAlign: 'left' }}>
            Name the application "DrChrono" then follow the steps below:
            <br /> 1) Set this url as redirect url in your app:
            "https://app.alltheapps.io/integrations/drchrono" <br /> 2) Set the above webhook secret
            token in your drchrono account webhook secret token. <br /> After this, hit save.{' '}
          </Typography>

          <MDButton
            type="submit"
            variant="contained"
            color="info"
            fullWidth
            sx={{ mt: 2 }}
            style={{ fontSize: '0.9rem', textTransform: 'capitalize', padding: '0 5rem' }}
          >
            Connect
          </MDButton>
        </MDBox>
      </Card>
    </div>
  )
}

export default GoHighLevel

import React, { useState } from 'react'
import MDModal from 'components/MDModal'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import axios from 'axios'
import PropTypes from 'prop-types'
import MDBox from 'components/MDBox'
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useAppServices } from 'hook/services'

function CreateQrCode({ open, onClose, handleRefresh }) {
  const [qrCodeText, setQrCodeText] = useState('')
  const [qrCodeSize, setQrCodeSize] = useState(150)

  const AppServices = useAppServices()

  const handleCreateQrCode = async () => {
    try {
      const response = await axios.post('http://localhost:8084/v1/uiapps/qrcode/qrcode-url', {
        text: qrCodeText,
        size: qrCodeSize,
      })

      console.log(response.data)
      handleRefresh()
      onClose() // Close the modal after creating QR code
    } catch (error) {
      console.error('Error creating QR code:', error)
    }
  }

  return (
    <MDModal open={open} onClose={onClose}>
      <MDBox>
        <MDTypography variant="h5" sx={{ paddingBottom: 4 }}>
          QR Code
        </MDTypography>

        <MDInput
          label="Text"
          fullWidth
          value={qrCodeText}
          onChange={(e) => setQrCodeText(e.target.value)}
          sx={{ marginBottom: 5 }}
        />

        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel>Size</InputLabel>
          <Select value={qrCodeSize} onChange={(e) => setQrCodeSize(e.target.value)}>
            <MenuItem value={150}>Small</MenuItem>
            <MenuItem value={300}>Medium</MenuItem>
            <MenuItem value={450}>Large</MenuItem>
          </Select>
        </FormControl>

        <MDButton
          variant="contained"
          onClick={handleCreateQrCode}
          style={{ background: 'whitesmoke', marginBottom: 2 }}
        >
          Create
        </MDButton>
      </MDBox>
    </MDModal>
  )
}

CreateQrCode.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
}

export default CreateQrCode

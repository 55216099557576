import React, { useState } from 'react'
import ScratchCard from 'react-scratchcard-v4'
import smileFace from 'assets/images/smile.jpg'
import sadFace from 'assets/images/sad.png'
import IMG from 'assets/images/barcode.png'

const ScratchCardGame = ({ handleRefresh }) => {
  const [state, setState] = useState({
    completed: false,
    showPopup: false,
    result: null,
    randomNumber: null,
  })

  const handleComplete = () => {
    const newRandomNumber = Math.floor(10000000000000 + Math.random() * 90000000000000)
    const didWin = Math.random() < 0.5

    setState({
      completed: true,
      showPopup: true,
      result: didWin ? 'won' : 'lost',
      randomNumber: newRandomNumber,
    })
  }

  const closePopup = () => {
    setState({
      completed: false,
      showPopup: false,
      result: null,
      randomNumber: null,
    })
    handleRefresh()
  }

  const renderBlinkingNumber = () => {
    const digits = state.randomNumber.toString().split('')
    return digits.map((digit, index) => (
      <span key={index} className={`random_number ${index % 2 === 0 ? 'blink' : ''}`}>
        {digit}
      </span>
    ))
  }

  return (
    <div className="card_img">
      <div style={{ marginTop: '7rem' }}>
        <ScratchCard
          width={600}
          height={100}
          image={IMG}
          finishPercent={70}
          onComplete={handleComplete}
        />
        {state.completed && (
          <div>
            <h2>Random Number:</h2>
            <p>{state.randomNumber}</p>
          </div>
        )}
        {state.showPopup && (
          <Popup
            result={state.result}
            renderBlinkingNumber={renderBlinkingNumber}
            closePopup={closePopup}
          />
        )}
      </div>
    </div>
  )
}

const Popup = ({ result, renderBlinkingNumber, closePopup }) => (
  <div className="popup">
    <div className="popup-content">
      {result === 'won' ? (
        <>
          <img src={smileFace} alt="Smile Emoji" />
          <p>Your result</p>
          <p className="random_number">{renderBlinkingNumber()}</p>
        </>
      ) : (
        <>
          <img src={sadFace} alt="Sad Emoji" />
          <p>Sorry, you lost this time. Try again!</p>
          <p className="random_number">{renderBlinkingNumber()}</p>
        </>
      )}
      <button className="popUp-btn" onClick={closePopup}>
        Close
      </button>
    </div>
  </div>
)

export default ScratchCardGame

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react'

// react-router-dom components
import {
  Link,
  useLocation,
  useParams,
  // useNavigate
} from 'react-router-dom'

// @mui material components
import Card from '@mui/material/Card'
// import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
// import MuiLink from '@mui/material/Link'

// // @mui icons
// import FacebookIcon from '@mui/icons-material/Facebook'
// import GitHubIcon from '@mui/icons-material/GitHub'
// import GoogleIcon from '@mui/icons-material/Google'

// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout'

// Images
import bgImage from 'assets/images/bg-sign-in-basic.jpeg'
import { useFormik } from 'formik'
import { AuthVld } from 'validation'
import { useAppServices } from 'hook/services'
import { useUserInfo } from 'context/user'
import localforage from 'localforage'
import { useBrandInfo } from 'context/brand'
import { useAgencyInfo } from 'context/agency'
import CryptoJS from 'crypto-js'

// import { Button } from '@mui/material'

function GotoWebinar() {
  // const [rememberMe, setRememberMe] = useState(false)

  // const handleSetRememb  erMe = () => setRememberMe(!rememberMe)
  const Service = useAppServices()
  const [message, setMessage] = useState()
  const [userData, setuserData] = useState({})
  const { locationId, companyId } = useParams()
  console.log(locationId, 'locationId')
  console.log(companyId, 'companyId')

  const auth_url = `https://authentication.logmeininc.com/oauth/authorize?client_id=cebbb890-1b44-42f8-8db0-172f06d308ff&response_type=code&redirect_uri=https://app.ghl.store/integrations/webinar&state=${locationId}`
  const hanldeConnect = () => {
    var windowFeatures = 'width=600,height=600,menubar=no,location=no,toolbar=no,scrollbars=yes'

    // Open the URL in a new window with the specified features
    window.open(auth_url, '_blank', windowFeatures)
  }

  // const decript_data = (payload) => {
  //   const GHL_APP_SSO_KEY = 'fd791479-ff4f-48b2-a137-c849d71cefc4'
  //   const data = CryptoJS.AES.decrypt(payload, GHL_APP_SSO_KEY).toString(CryptoJS.enc.Utf8)

  //   console.log(JSON.parse(data), 'user seesion')
  //   setuserData(JSON.parse(data))
  // }
  // useEffect(async () => {
  //   const key = await new Promise((resolve) => {
  //     window.parent.postMessage({ message: 'REQUEST_USER_DATA' }, '*')
  //     console.log(window.parent.postMessage({ message: 'REQUEST_USER_DATA' }, '*'), 'test data')
  //     window.addEventListener('message', ({ data }) => {
  //       console.log(data, 'window.addEventListener')
  //       if (data.message === 'REQUEST_USER_DATA_RESPONSE') {
  //         decript_data(data.payload)
  //         console.log(data.payload)
  //         // return data.payload
  //       } else {
  //         // return 'databklf'
  //       }
  //     })
  //   })
  // }, [])
  return (
    <BasicLayout image={bgImage} type="notopbar">
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            GoTo webinar
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}></Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form">
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox> */}
            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="button"
                onClick={hanldeConnect}
                sx={{ mt: 4, mb: 1 }}
                fullWidth
              >
                Connect
              </MDButton>
              <MDTypography variant="button" color="error">
                {message}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  )
}

export default GotoWebinar

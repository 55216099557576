/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react'

// react-router-dom components
import {
  Link,
  useLocation,
  useParams,
  // useNavigate
} from 'react-router-dom'

// @mui material components
import Card from '@mui/material/Card'
// import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
// import MuiLink from '@mui/material/Link'

// // @mui icons
// import FacebookIcon from '@mui/icons-material/Facebook'
// import GitHubIcon from '@mui/icons-material/GitHub'
// import GoogleIcon from '@mui/icons-material/Google'

// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import { useAppServices } from 'hook/services'

import IframeModal from './components/modal'

// import { Button } from '@mui/material'

function GoHighLevel() {
  // const [rememberMe, setRememberMe] = useState(false)
  const searchParams = new URLSearchParams(document.location.search)
  const [message, setMessage] = useState('')
  const sessionKey = searchParams.get('sessionKey')
  const auth_url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=https://app.alltheapps.io/integrations/winloosedraw&client_id=660ad19d02785a5a40db7447-lvj6q5a7&scope=businesses.readonly businesses.write calendars.readonly calendars.write calendars/events.readonly calendars/events.write calendars/groups.readonly calendars/groups.write campaigns.readonly conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write links.readonly links.write locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly&state=&sessionKey=${sessionKey}`

  return (
    // <BasicLayout image={bgImage} type="notopbar">
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Card
        sx={{
          width: '40%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          p={2}
          mb={1}
        >
          <MDTypography variant="h5" gutterBottom style={{ color: 'white' }}>
            Connect with Win loose Draw
          </MDTypography>
        </MDBox>
        <MDBox style={{ margin: '8rem' }}></MDBox>
        <IframeModal />

        {/* <MDButton
        onClick={hanldeConnect} // Call handleConnectClick function when button is clicked
        type="submit"
        variant="contained"
        color="info"
        fullWidth
        sx={{ mt: 2 }}
        style={{ fontSize: '0.9rem', textTransform: 'capitalize' }}
      >
        Connect
      </MDButton> */}
        <MDTypography variant="button" color="error" sx={{ mt: 1 }}>
          {message}
        </MDTypography>
      </Card>
    </div>
    // </BasicLayout>
  )
}

export default GoHighLevel

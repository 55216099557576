import axios from 'axios'
import env from 'config'
import { setNotification, useNotifcation } from 'context/notification'
import { ReqMethods, ResponseStatus } from 'enums'
import { useAuth, useLogout } from './auth'

const BASE_URL = `${env.API_URL}/v1`
const SUPER_ADMIN_BASE_URL = `${env.API_URL}/api/v1`

function useServiceHandler() {
  const [, dispatch] = useNotifcation()
  const Logout = useLogout()

  return (fn) =>
    async (method, path, { query, payload, token, apiKey, toaster, message, error } = {}) => {
      try {
        const res = await fn(method, path, { query, payload, token, apiKey })
        console.log('API - RESPONSE', res, toaster, message, error)
        toaster &&
          setNotification(dispatch, {
            open: true,
            message: message || res.data.message,
            title: 'Success',
          })

        return { response: res.data }
      } catch (err) {
        console.log('API- ERROR', err.response?.data || err)

        // expire error : jwt expired
        if (err.response && err.response.status === ResponseStatus.UNAUTHORIZED) {
          setNotification(dispatch, {
            open: true,
            message: error || 'session expired',
            title: 'Error',
            severity: 'error',
          })
          setTimeout(Logout, 4000)
          return { error: err.response?.data || err }
        }

        const customError = err.response?.data?.error
          ? `${err.response?.data?.message} \n${err.response?.data?.error}`
          : err.response?.data?.message

        toaster &&
          setNotification(dispatch, {
            open: true,
            message: error || customError || err.message,
            severity: 'error',
            title: 'Error',
          })
        return { error: err.response ? err.response.data : err }
      }
    }
}

function useCallService() {
  const authToken = useAuth()
  const serviceHandler = useServiceHandler()

  const CallService = (method, path, { query, payload, token = true, apiKey = null }) => {
    const pathname = query ? `${path}?${query}` : path
    const config = {}

    if (token) config.headers = { 'x-auth-token': `Bearer ${authToken || token}` }
    if (apiKey) config.headers = { apiKey }

    const details = {}

    if (payload) details.payload = payload
    details.config = config

    return axios[method](pathname, ...Object.values(details))
  }

  return serviceHandler(CallService)
}

function useAppServices() {
  const { GET, POST, PUT, DELETE } = ReqMethods
  const CallService = useCallService()

  /**
    Option for service is the object that could has the following properties
    query, payload, token, apiKey
  */

  const APIs = {
    auth: {
      login: (options) => CallService(POST, `${BASE_URL}/auth/login`, options),
    },
    user: {
      get: (options) => CallService(GET, `${BASE_URL}/user`, options),
    },
    brand: {
      get: (options) => CallService(GET, `${BASE_URL}/brand`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/brand`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/brand/filter`, options),
    },
    spreadsheet: {
      getToken: (options) => CallService(GET, `${BASE_URL}/spreadsheet/get-token`, options),
    },
    agency: {
      get: (options) => CallService(GET, `${BASE_URL}/agency`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/agency`, options),
    },
    app_token: {
      get_credentials: (options) =>
        CallService(GET, `${SUPER_ADMIN_BASE_URL}/app_token/get_credentials`, options),
    },
    webhook: {
      get_locations: (options) => CallService(GET, `${BASE_URL}/webhook/get_locations`, options),
      update_app_token: (options) =>
        CallService(POST, `${BASE_URL}/webhook/update_app_token`, options),
    },
    calnedermaps: {
      get: (options) => CallService(GET, `${BASE_URL}/calnedermaps`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/calnedermaps`, options),
      create: (options) => CallService(POST, `${BASE_URL}/calnedermaps`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/calnedermaps`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/calnedermaps/filter`, options),
      GetCalendars: (options) => CallService(GET, `${BASE_URL}/calnedermaps/GetCalendars`, options),
      GetCalendarsData: (options) =>
        CallService(GET, `${BASE_URL}/calnedermaps/GetCalendarsData`, options),
    },
    deeplinks: {
      Create: (options) => CallService(POST, `${BASE_URL}/uiapps/deeplinks/create`, options),
      Get: (options) => CallService(GET, `${BASE_URL}/uiapps/deeplinks/get-deeplinks`, options),
      GetDeeplink: (options) => CallService(GET, `${BASE_URL}/uiapps/deeplinks/:id`, options),
    },
    servicem8: {
      Get: (options) => CallService(GET, `${BASE_URL}/servicem8/token`, options),
    },
    qrcode: {
      Create: (options) => CallService(POST, `${BASE_URL}/uiapps/qrcode/create`, options),
      Get: (options) => CallService(GET, `${BASE_URL}/uiapps/qrcode/get-qrcode`, options),
      GetDeeplink: (options) => CallService(GET, `${BASE_URL}/uiapps/deeplinks/:id`, options),
    },
    sharelinks: {
      Create: (options) => CallService(POST, `${BASE_URL}/uiapps/deeplinks/create`, options),
      Get: (options) => CallService(GET, `${BASE_URL}/uiapps/sharelinks/get-sharelink`, options),
      GetDeeplink: (options) => CallService(GET, `${BASE_URL}/uiapps/deeplinks/:id`, options),
    },
    services: {
      ghl: {
        call_service: (options) => CallService(POST, `${BASE_URL}/services/ghl/`, options),
      },
      webinar: {
        call_service: (options) => CallService(POST, `${BASE_URL}/services/webinar/`, options),
        associate: (options) =>
          CallService(POST, `${BASE_URL}/services/webinar/associate`, options),
      },
      agencyapp: {
        call_service: (options) => CallService(POST, `${BASE_URL}/services/ghl/`, options),
        associate: (options) =>
          CallService(POST, `${BASE_URL}/services/agencyapp/associate`, options),
      },
      stripe: {
        call_service: (options) => CallService(POST, `${BASE_URL}/services/ghl/`, options),
        associate: (options) => CallService(POST, `${BASE_URL}/services/stripe/associate`, options),
      },
      messagebird: {
        call_service: (options) => CallService(POST, `${BASE_URL}/services/ghl/`, options),
        associate: (options) =>
          CallService(POST, `${BASE_URL}/services/messagebird/associate`, options),
      },
      winloosedraw: {
        call_service: (options) => CallService(POST, `${BASE_URL}/services/ghl/`, options),
        associate: (options) =>
          CallService(POST, `${BASE_URL}/services/winloosedraw/associate`, options),
      },
      spreadsheet: {
        call_service: (options) => CallService(POST, `${BASE_URL}/services/ghl/`, options),
        associate: (options) =>
          CallService(POST, `${BASE_URL}/services/spreadsheet/associate`, options),
      },
    },
  }

  return APIs
}

export { useAppServices, useCallService }

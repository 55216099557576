import React, { useState } from 'react'
import MDModal from 'components/MDModal'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import axios from 'axios'
import PropTypes from 'prop-types'
import MDBox from 'components/MDBox'
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useAppServices } from 'hook/services'

function CreateDeeplink({ open, onClose ,handleRefresh}) {
  const AppServices = useAppServices()

  const [recipientEmail, setRecipientEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [body, setBody] = useState('')
  const [profileId, setProfileId] = useState('')
  const [profileUsername, setProfileUsername] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [message, setMessage] = useState('')
  const [channelUsername, setChannelUsername] = useState('')
  const [type, setType] = useState('')

  const handleSubmit = async () => {
    try {
      let dataToSend

      if (type === 'email') {
        dataToSend = {
          recipientEmail,
          subject,
          body,
        }
      } else if (type === 'facebook') {
        dataToSend = {
          profile_id: profileId,
        }
      } else if (type === 'linkedin') {
        dataToSend = {
          profile_id: profileId,
        }
      } else if (type === 'instagram') {
        dataToSend = {
          profileUsername,
        }
      } else if (type === 'whatsapp') {
        dataToSend = {
          phoneNumber,
          message,
        }
      } else if (type === 'youtube') {
        dataToSend = {
          channelUsername,
        }
      } else if (type === 'tiktok') {
        dataToSend = {
          profileUsername,
        }
      }

      const response = await axios.post('http://localhost:8084/v1/uiapps/deeplinks/create', {
        type,
        data: dataToSend,
      })
      // const response = await AppServices.deeplinks.Create({
      //   type,
      //   data: dataToSend,
      // })
      const responseData = response.data.data
      console.log(responseData)
      onClose()
      handleRefresh()
    } catch (error) {
      console.error('Error', error)
    }
  }

  return (
    <MDModal open={open} onClose={onClose}>
      <MDBox>
        <MDTypography variant="h5" sx={{ paddingBottom: 4 }}>
          Deeplink
        </MDTypography>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel>Type</InputLabel>
          <Select value={type} onChange={(e) => setType(e.target.value)}>
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="facebook">Facebook</MenuItem>
            <MenuItem value="linkedin">LinkedIn</MenuItem>
            <MenuItem value="instagram">Instagram</MenuItem>
            <MenuItem value="whatsapp">WhatsApp</MenuItem>
            <MenuItem value="youtube">YouTube</MenuItem>
            <MenuItem value="tiktok">TikTok</MenuItem>
          </Select>
        </FormControl>
        {type === 'email' ? (
          <>
            <MDInput
              label="Recipient Email"
              value={recipientEmail}
              fullWidth
              onChange={(e) => setRecipientEmail(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <MDInput
              label="Subject"
              value={subject}
              fullWidth
              onChange={(e) => setSubject(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <MDInput
              label="Body"
              value={body}
              fullWidth
              onChange={(e) => setBody(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
          </>
        ) : type === 'facebook' || type === 'linkedin' ? (
          <MDInput
            label={type === 'facebook' ? 'Profile ID' : 'LinkedIn Profile ID'}
            value={profileId}
            fullWidth
            onChange={(e) => setProfileId(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
        ) : type === 'instagram' || type === 'tiktok' ? (
          <MDInput
            label={type === 'instagram' ? 'Username' : 'TikTok Username'}
            value={profileUsername}
            fullWidth
            onChange={(e) => setProfileUsername(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
        ) : type === 'whatsapp' ? (
          <>
            <MDInput
              label="Phone Number"
              value={phoneNumber}
              fullWidth
              onChange={(e) => setPhoneNumber(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <MDInput
              label="Message"
              value={message}
              fullWidth
              onChange={(e) => setMessage(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
          </>
        ) : type === 'youtube' ? (
          <MDInput
            label="Channel Username"
            value={channelUsername}
            fullWidth
            onChange={(e) => setChannelUsername(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
        ) : null}
        <Button
          onClick={handleSubmit}
          variant="contained"
          style={{color:"white"}}
        >
          Create
        </Button>
      </MDBox>
    </MDModal>
  )
}

CreateDeeplink.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
}

export default CreateDeeplink

import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import QueryString from 'qs'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { ReqMethods, ghl } from 'enums'
import env from 'config'
import { useAppServices } from 'hook/services'
import { useBrandInfo } from 'context/brand'
// import { AgencyContext } from '../../../context/Agency.context'

function AgencyApp() {
  // client_id:cebbb890-1b44-42f8-8db0-172f06d308ff
  // client_secret:wdGxVkDXaOIjyIWYZBFl0U02
  const navigate = useNavigate()
  const { search } = useLocation()
  const code = new URLSearchParams(search).get('code')
  const state_data = new URLSearchParams(search).get('state')
  const AppServices = useAppServices()
  const [Error, setError] = useState('')
  const [brand, update] = useBrandInfo()
  const companyId = state_data.split(',')[0]
  const app_id = state_data.split(',')[1]
  const type = state_data.split(',')[2]

  const associate = async (creds) => {
    console.log(type, 'type')
    const appId = type === 'free' ? '66058d337f93c5a59eea00f7' : '65afa071288655d0204aba41'

    console.log(creds, 'creds')
    const payload = {
      app_id: app_id,
      companyId: companyId,
      type: type,

      app_configuration: {
        company_id: creds.companyId,
        access_token: creds.access_token,
        refresh_token: creds.refresh_token,
        location_id: creds.locationId,
        app_id: appId,
      },
      connection_status: 'active',
    }

    console.log(payload, 'associate payload')

    const { response } = await AppServices.services.agencyapp.associate({
      payload,
    })
    // const { response } = await AppServices.location_apps.update({ payload })
    console.log(response, 'associate response')
    if (response) {
      setError('App Successfully Integrated')
      setTimeout(() => {
        // window.close()
      }, 2000)
    }

    if (!response) {
      return setError('Something went wrong while in associate integration, please try latter')
      window.close()
    }
    // return navigate(-1)
  }

  const getCreds = async () => {
    const client_id =
      type === 'free' ? '66058d337f93c5a59eea00f7-lxvxgz42' : '65afa071288655d0204aba41-ltiv2jks'
    const client_secret =
      type === 'free'
        ? '1ee00bc7-a5db-4bc0-9666-abb6096785b9'
        : 'bdd29b56-390c-4bb8-845c-ffe02e841c6e'
    const payload = {
      method: ReqMethods.POST,
      path: ghl.APIs.oauth,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      payload: QueryString.stringify({
        client_id: client_id,
        client_secret: client_secret,
        grant_type: 'authorization_code',
        code,
        refresh_token: '',
      }),
    }
    console.log(payload, 'getCreds')

    const { response } = await AppServices.services.agencyapp.call_service({
      payload,
    })
    console.log(response, 'response')

    if (!response)
      return setError('Something went wrong while in Cred integration, please try latter')
    // if(location_id !== response.data.locationId) return setError('location ID mismatch')

    return response && associate(response.data)
  }

  const onLoad = () => {
    console.log(code)
    getCreds()
  }

  useEffect(onLoad, [])

  return (
    <MDBox>
      {!Error && <MDBox>Integrating please wait...</MDBox>}
      {Error && (
        <MDBox>
          {Error}
          <MDButton onClick={() => navigate(-2)}>Go Back</MDButton>
        </MDBox>
      )}
    </MDBox>
  )
}

export default AgencyApp

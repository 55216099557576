import React, { useState, useEffect } from 'react'
import SplashScreen from './component/SplashScreen'
import ScratchCard from './component/Card'
import './style.css'

const ScratchCardGame = () => {
  const [showSplash, setShowSplash] = useState(true)
  const [refresh, setrefresh] = useState(false)

  useEffect(() => {
    const asyncOperation = async () => {
      setShowSplash(true)
      await new Promise((resolve) => setTimeout(resolve, 3000))
      setShowSplash(false)
    }

    asyncOperation()
  }, [refresh])

  return (
    <div>
      {showSplash ? (
        <SplashScreen />
      ) : (
        <>
          <h1 className="scratch-card-title">Scratch & WIN</h1>
          <ScratchCard handleRefresh={() => setrefresh(!refresh)} />
        </>
      )}
    </div>
  )
}

export default ScratchCardGame

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react

* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com


* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useLocation, useParams } from 'react-router-dom'

// @mui material components
import Card from '@mui/material/Card'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Snackbar } from '@mui/material'

function GoHighLevel() {
  const { search } = useLocation()
  const [loading, setLoading] = useState(false)
  const code = new URLSearchParams(search).get('code')
  const { companyId, app_id, locationId, access_token } = useParams()
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  })
  const { vertical, horizontal, open } = state

  console.log(code)

  const auth_url = 'https://api.raindrop.io/v1/oauth/authorize?client_id=66e038e8e33a09c79fc552ed&redirect_uri=http://localhost:3000/raindrop' 
  //` https://raindrop.io/oauth/access_token=${access_token}`

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  const handleConnect = (e) => {
    e.preventDefault()
    window.open(auth_url)
  }

  const Authorize = async () => {

    // const payLoad = {
    //     "grant_type": "authorization_code",
    //     "code": code,
    //     "client_id": "66e038e8e33a09c79fc552ed",
    //     "client_secret": "9cff91a8-7fcb-4ceb-a311-9e075849ddae",
    //     "redirect_uri": "http://localhost:3000/raindrop"
    // }

    try {
        const response = await axios.post(
        `http://localhost:8082/v1/raindrop/authorize`,
            // ' https://raindrop.io/oauth/access_token',
        //   'https://marketplace.ghl.store/v1/trello/create-credentials',
        //   payLoad,
        //   {
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //   }
        )
        setState({ ...state, open: true })
        console.log(response.data)
      } catch (error) {
        console.error('Error connecting:', error.message)
      } finally {
        setLoading(false)
      }

  }


  const onLoad = () => {
    console.log(code,'code')
    if(code)
        Authorize()
  }

  useEffect(onLoad, [])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundSize: 'cover',
      }}
    >
      <Card
        sx={{
          width: '35%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <MDTypography variant="h4" gutterBottom style={{ color: 'black' }}>
          Connect with RainDrop
        </MDTypography>

        <MDBox component="form" onSubmit={handleConnect} sx={{ mt: 15 }}>
          <MDButton
            type="submit"
            variant="contained"
            color="info"
            fullWidth
            sx={{ mt: 2 }}
            style={{ fontSize: '0.9rem', textTransform: 'capitalize', padding: '0 5rem' }}
          >
            {loading ? 'Connecting...' : 'Connect'}
          </MDButton>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message="Connected Successfully"
            key={vertical + horizontal}
          />
        </MDBox>
      </Card>
    </div>
  )
}

export default GoHighLevel

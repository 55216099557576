import { Environment } from '../enums'

const env = {
  // API_URL : 'https://dev.walletcampaigns.com/api',
  // API_URL : 'http://35.176.217.108:8080/api'
  // API_URL: 'http://localhost:8082',
  API_URL: 'https://marketplace.ghl.store',
  GHL: {
    CLIENT_ID: '6246e2fcfc3dabe2d3301bc5-l1gcm0ww',
    CLIENT_SECRET: '67be1628-cb7c-4530-95a3-a83e60093cd0',
    SCOPE:
      'contacts.readonly contacts.write locations.readonly locations/customFields.readonly locations/customFields.write workflows.readonly users.readonly users.write campaigns.readonly conversations/message.readonly conversations/message.write locations/customValues.readonly locations/customValues.write opportunities.readonly opportunities.write forms.readonly links.readonly links.write surveys.readonly locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly',
    REDIRECT: {
      ASSOCIATE: 'http://localhost:3000/integrations/ghl/associate',
    },
  },

  Webinar: {
    // client_id:cebbb890-1b44-42f8-8db0-172f06d308ff
    // client_secret:wdGxVkDXaOIjyIWYZBFl0U02
    CLIENT_ID: 'cebbb890-1b44-42f8-8db0-172f06d308ff',
    CLIENT_SECRET: 'wdGxVkDXaOIjyIWYZBFl0U02',
    SCOPE:
      'identity:scim.me collab: support: identity:scim.org identity: call-control.v1.calls.control call-events.v1.events.read call-events.v1.notifications.manage call-history.v1.notifications.manage calls.v2.initiate cr.v1.read fax.v1.notifications.manage fax.v1.read fax.v1.write messaging.v1.notifications.manage messaging.v1.read messaging.v1.send messaging.v1.write presence.v1.notifications.manage presence.v1.read presence.v1.write recording.v1.notifications.manage recording.v1.read users.v1.lines.read voice-admin.v1.read voice-admin.v1.write voicemail.v1.notifications.manage voicemail.v1.voicemails.read voicemail.v1.voicemails.write webrtc.v1.read webrtc.v1.write',
    REDIRECT: {
      auth: 'http://localhost:3002/integrations/webinar',
    },
  },
}

// if(process.env.REACT_APP_ENV === Environment.DEVELOPMENT){
// }

// if(process.env.REACT_APP_ENV === Environment.PRODUCTION){

// }

export default env

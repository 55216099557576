import React, { useState } from 'react'
import './SlotMachine.css'

const SlotMachine = () => {
  const [reels, setReels] = useState([{ fruit: '💲' }, { fruit: '💲' }, { fruit: '💲' }])
  const [message, setMessage] = useState('Try your luck and win some mad prizes!')
  const [playing, setPlaying] = useState(false)
  const [hasWon, setHasWon] = useState(false)

  const fruits = ['🍒', '🍋', '🍇']
  const prizes = [
    '💰',
    '🥧',
    '🍔',
    '🍩',
    '🍨',
    '🍕',
    '🥞',
    '🌹',
    '🐵',
    '🐶',
    '🎮',
    '🎹',
    '🎻',
    '🎸',
    '🎷',
    '🧦',
    '👑',
    '🚲',
    '🛸',
  ]

  const getRandom = (arr) => arr[Math.floor(Math.random() * arr.length)]

  const getResults = () => {
    setReels((prevReels) => prevReels.map((reel) => ({ ...reel, fruit: getRandom(fruits) })))
    setPlaying(false)
    checkIfWon()
  }

  const play = () => {
    setPlaying(true)
    setHasWon(false)
    setTimeout(getResults, 2000)
  }

  // const checkIfWon = () => {
  //   setHasWon(reels.every((reel) => reel.fruit === reels[0].fruit))
  //   setMessage(
  //     reels.every((reel) => reel.fruit === reels[0].fruit)
  //       ? `Congratulations! You win this awesome prize of ${getRandom(prizes)}`
  //       : 'Sorry, try again!'
  //   )
  // }
  const checkIfWon = () => {
    const allSame = reels.every((reel) => reel.fruit === reels[0].fruit)
    const winningPercentage = Math.random() * 100

    const hasWon = allSame && winningPercentage <= 100

    setHasWon(hasWon)

    setMessage(
      hasWon
        ? `Congratulations! You win this awesome prize of ${getRandom(prizes)}`
        : 'Sorry, try again!'
    )
  }

  const handleClick = () => {
    play()
  }

  const reelsList = reels.map((reel, index) => (
    <div key={index} className={`Reel Reel-plate Reel-plate-${index + 1} ${playing ? 'spin' : ''}`}>
      <span>{reel.fruit}</span>
      <span>{getRandom(fruits)}</span>
      <span>{getRandom(fruits)}</span>
    </div>
  ))

  return (
    <div className="Machine">
      <div className="Alarm-container">
        <div className={`Alarm ${hasWon ? 'alert' : ''}`}>
          <div className="Alarm-bulb" />
        </div>
      </div>
      <div className="Lights">
        {[...Array(9)].map((_, index) => (
          <div key={index} className={`Light-bulb ${index % 2 === 1 ? 'white' : ''}`} />
        ))}
      </div>
      <div className="Reels">{reelsList}</div>
      <button className="slot-button" onClick={handleClick} disabled={playing}>
        Play
      </button>
      <div className="Slot">
        <div className="Slot-inner" />
      </div>
      <div className="Machine-bottom" />
      <div className={`Message ${!playing ? 'Message-visible' : ''}`}>
        <p>{!playing && message}</p>
      </div>
    </div>
  )
}

export default SlotMachine

import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import { useLocation, useParams } from 'react-router-dom'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import Swal from 'sweetalert2'
const GoHighLevel = () => {
  const [apiKey, setApiKey] = useState('')
  const [apiSecret, setApiSecret] = useState('')
  const [workspaceId, setWorkspaceId] = useState('')

  const [userData, setuserData] = useState({})
  const { locationId, companyId, app_id } = useParams()
  const [loading, setLoading] = useState(false)
  const { search } = useLocation()
  const type = new URLSearchParams(search).get('type')

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const payload = {
      app_cred: {
        apiKey,
        apiSecret,
        workspaceId,
      },
      location_id: locationId, //kEyKMducTgZLJcLFgw27
      company_id: companyId, //6NKdTpPe08P8DR5Jg7k6
      app_id: app_id,
      connection_status: 'active',
      type: type,
    }
    console.log(payload, 'payload')
    try {
      const response = await axios.post(
        // 'http://localhost:8082/v1/pdfgenerator/create-credentials',
        'https://marketplace.ghl.store/v1/pdfgenerator/create-credentials',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      console.log(response.data)

      if (response.data) {
        Swal.fire({
          title: 'Connected Successfully',
          icon: 'success',
        })
      }
    } catch (error) {
      Swal.fire({
        title: 'Something wents wrong',
      })
      console.error('Error connecting:', error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Card
        sx={{
          width: '35%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <MDTypography variant="h4" gutterBottom>
          Connect with PDF Generator
        </MDTypography>

        <MDBox component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            type="text"
            label="apiKey"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
          />

          <TextField
            type="text"
            label="apiSecret"
            value={apiSecret}
            onChange={(e) => setApiSecret(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
          />

          <TextField
            type="text"
            label="Workspace Email"
            value={workspaceId}
            onChange={(e) => setWorkspaceId(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
          />

          <MDButton
            type="submit"
            variant="contained"
            color="info"
            fullWidth
            sx={{ mt: 2 }}
            style={{ fontSize: '0.9rem', textTransform: 'capitalize' }}
          >
            Connect
          </MDButton>
        </MDBox>
      </Card>
    </div>
  )
}

export default GoHighLevel

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import PageLayout from 'examples/LayoutContainers/PageLayout'
import { useLogout } from 'hook/auth'
import React, { useEffect } from 'react'
import CryptoJS from 'crypto-js'
function NotFound() {
  const logout = useLogout()
  useEffect(async () => {
    const GHL_APP_SSO_KEY = 'fd791479-ff4f-48b2-a137-c849d71cefc4'
    const key = {
      message: 'REQUEST_USER_DATA_RESPONSE',
      payload:
        'U2FsdGVkX19Zu9c8ug/GBflCnAei1LNZpw8sipEt3iLqJYszURyDYQ0FnBYUk8JZG1m+zq+DS7iLvWu9bxu3feLUJHfXM+Fgb0846SED79zYj757qcNluSJl9iOJYKYmm1f4ycFn4YMkrV2rz8Cvv4MiYdX6Not1aZIDin+ZmR9/aALFn6y4oUdqb0tCfylKOIRYHlle4OPWfIpY04Tly1RBMeu5j0J3VrRsCnxvoWdI/AZLQD9RrEmERH5IgdVz28RSnHY7NacpkBGrQCQlYJyRa5GqGPTQR59BmRqMEcZqMJiB1833Yy74UGxjw2eifXuKCIxXnSVXKSSaPlgvucrnV20g/wlvdugi6yCzZGY=',
    }
    const data = CryptoJS.AES.decrypt(key.payload, GHL_APP_SSO_KEY).toString(CryptoJS.enc.Utf8)

    console.log(JSON.parse(data), 'user seesion')
    // var parentURL = window.parent.location.href
    // alert('parentURL')
    // const key = await new Promise((resolve) => {
    //   window.parent.postMessage({ message: 'REQUEST_USER_DATA' }, '*')
    //   console.log(window.parent.postMessage({ message: 'REQUEST_USER_DATA' }, '*'), 'test data')
    //   window.addEventListener('message', ({ data }) => {
    //     console.log(data, 'window.addEventListener')
    //     if (data.message === 'REQUEST_USER_DATA_RESPONSE') {
    //       return data.payload
    //     } else {
    //       return 'databklf'
    //     }
    //   })
    // })
    // console.log(key, 'keykeykeykeykeykeykey')
    // const res = await fetch('/decrypt-sso', {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ key }),
    // })
    // const data = await res.json()
    // console.log(data)
  }, [])

  return (
    <PageLayout>
      <MDBox p={2} textAlign="right">
        <MDTypography onClick={logout} variant="button" color="text" fontWeight="medium">
          Logout
        </MDTypography>
      </MDBox>
      <MDBox
        borderRadius="lg"
        p={2}
        display="flex"
        flexDirectionContent="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        sx={{ height: '80vh' }}
      >
        <MDTypography variant="h4" fontWeight="medium" mt={1}>
          404 Not Found
        </MDTypography>
      </MDBox>
    </PageLayout>
  )
}

export default NotFound

import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import QueryString from 'qs'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { ReqMethods, ghl } from 'enums'
import env from 'config'
import { useAppServices } from 'hook/services'
import { useBrandInfo } from 'context/brand'
// import { AgencyContext } from '../../../context/Agency.context'

function Messagebird() {
  // client_id:cebbb890-1b44-42f8-8db0-172f06d308ff
  // client_secret:wdGxVkDXaOIjyIWYZBFl0U02
  const navigate = useNavigate()
  const { search } = useLocation()
  const code = new URLSearchParams(search).get('code')
  const state_data = new URLSearchParams(search).get('state')
  const AppServices = useAppServices()
  const [Error, setError] = useState('')
  const [brand, update] = useBrandInfo()
  // const app_id = state_data.split(',')[0]
  const companyId = state_data

  const associate = async (creds) => {
    const payload = {
      app_id: '652e65a3d8698874870e6913',
      companyId: companyId,
      type: 'messagebird_free_app',

      // creds: creds,
      app_configuration: {
        company_id: creds.companyId,
        access_token: creds.access_token,
        refresh_token: creds.refresh_token,
        location_id: creds.locationId,
        app_id: '652e65a3d8698874870e6913',
      },
      connection_status: 'active',
    }

    console.log(payload, 'associate payload')

    const { response } = await AppServices.services.clickup.associate({
      payload,
    })
    // const { response } = await AppServices.location_apps.update({ payload })
    console.log(response, '@@associae 1')
    if (response) {
      setError('App Successfully Integrated')
      setTimeout(() => {
        window.close()
      }, 2000)
    }

    if (!response) {
      return setError('Something went wrong while in associate integration, please try latter')
      window.close()
    }
    // return navigate(-1)
  }

  const getCreds = async () => {
    const payload = {
      method: ReqMethods.POST,
      path: ghl.APIs.oauth,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      payload: QueryString.stringify({
        client_id: '652e65a3d8698874870e6913-lvktycl4',
        client_secret: 'e878084c-300d-410c-87f9-ee3510a39153',
        grant_type: 'authorization_code',
        code,
        refresh_token: '',
      }),
    }
    console.log(payload, 'getCreds')

    const { response } = await AppServices.services.messagebird.call_service({
      payload,
    })
    console.log(response, '@@@@callservice 2')

    if (!response)
      return setError('Something went wrong while in Cred integration, please try latter')
    // if(location_id !== response.data.locationId) return setError('location ID mismatch')

    return response && associate(response.data)
  }

  const onLoad = () => {
    console.log(code)
    getCreds()
  }

  useEffect(onLoad, [])

  return (
    <MDBox>
      {!Error && <MDBox>Integrating please wait</MDBox>}
      {Error && (
        <MDBox>
          {Error}
          <MDButton onClick={() => navigate(-2)}>Go Back</MDButton>
        </MDBox>
      )}
    </MDBox>
  )
}

export default Messagebird

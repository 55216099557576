import React, { useEffect, useState } from 'react'

const SplashScreen = () => {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const splashTimer = setTimeout(() => {
      setIsVisible(false)
    }, 3000)

    return () => clearTimeout(splashTimer)
  }, [])

  return <div className={`splash-screen ${isVisible ? 'visible' : 'hidden'} splash_image`}></div>
}

export default SplashScreen

import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import QueryString from 'qs'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { ReqMethods, webinar } from 'enums'
import env from 'config'
import { useAppServices } from 'hook/services'
import { useBrandInfo } from 'context/brand'
import localforage from 'localforage'
import Integration from '..'
// import { AgencyContext } from '../../../context/Agency.context'

function Webinar() {
  // client_id:cebbb890-1b44-42f8-8db0-172f06d308ff
  // client_secret:wdGxVkDXaOIjyIWYZBFl0U02
  const navigate = useNavigate()
  const { search } = useLocation()
  const code = new URLSearchParams(search).get('code')
  const state_data = new URLSearchParams(search).get('state')
  const AppServices = useAppServices()
  const [Error, setError] = useState('')
  const [brand, update] = useBrandInfo()
  // const app_id = state_data.split(',')[0]
  const locationId = state_data

  const associate = async (creds) => {
    // alert('jkgh')
    const payload = {
      app_id: '653a59be5846f3ae5689407b',
      locationId: locationId,
      type: 'gotowebinar',
      app_configuration: {
        access_token: creds.access_token,
        expires_in: creds.expires_in,
        principal: creds.principal,
        refresh_token: creds.refresh_token,
        token_type: creds.token_type,
      },
      connection_status: 'active',
    }

    console.log(payload, 'associate payload')

    const { response } = await AppServices.services.webinar.associate({
      payload,
    })
    // const { response } = await AppServices.location_apps.update({ payload })
    console.log(response, 'associate response')
    if (response) {
      setError('App Successfully Integrated')
      setTimeout(() => {
        window.close()
      }, 2000)
    }

    if (!response) {
      return setError('Something went wrong while in associate integration, please try latter')
      window.close()
    }
    // return navigate(-1)
  }
  const getCreds = async () => {
    const payload = {
      method: ReqMethods.POST,
      path: webinar.APIs.oauth,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      payload: {
        code: code,
        client_id: env.Webinar.CLIENT_ID,
        client_secret: env.Webinar.CLIENT_SECRET,
        redirect_uri: env.Webinar.REDIRECT.auth,
        grant_type: 'authorization_code',
      },
    }
    console.log(payload, 'getCreds')

    const { response } = await AppServices.services.webinar.call_service({
      payload,
    })
    console.log(response, 'response')

    if (!response)
      return setError('Something went wrong while in Cred integration, please try latter')
    // if(location_id !== response.data.locationId) return setError('location ID mismatch')

    return response && associate(response.data)
  }

  const onLoad = () => {
    console.log(code)
    getCreds()
  }

  useEffect(onLoad, [])

  return (
    <MDBox>
      {!Error && <MDBox>Integrating please wait...</MDBox>}
      {Error && (
        <MDBox>
          {Error}
          <MDButton onClick={() => navigate(-2)}>Go Back</MDButton>
        </MDBox>
      )}
    </MDBox>
  )
}

export default Webinar

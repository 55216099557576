import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
function GoHighLevel() {
  const [message, setMessage] = useState()
  const { companyId } = useParams()

  const handleCopy = () => {
    const text = `https://alltheapps.io/app/660ad19d02785a5a40db7447/${companyId}/{{location.id}}?sessionKey={{user.sessionKey}}`

    // Create a temporary textarea element to hold the text
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.setAttribute('readonly', '')
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'

    // Append the textarea to the body
    document.body.appendChild(textarea)

    textarea.select()

    document.execCommand('copy')

    document.body.removeChild(textarea)
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Card
        sx={{
          width: '40%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          p={2}
          mb={1}
        >
          <MDTypography variant="h6" gutterBottom style={{ color: 'white', fontSize: '14px' }}>
            Connect with Custom Menu Links
          </MDTypography>
        </MDBox>
        <MDBox style={{ margin: '8rem' }}></MDBox>

        <MDButton
          onClick={handleCopy}
          type="button"
          variant="contained"
          color="info"
          fullWidth
          sx={{ mt: 2 }}
          style={{ fontSize: '0.9rem', textTransform: 'capitalize' }}
        >
          Copy
        </MDButton>
        <MDTypography variant="button" color="success" sx={{ mt: 1 }}>
          {message}
        </MDTypography>
      </Card>
    </div>
  )
}

export default GoHighLevel

import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import MDBox from 'components/MDBox'
import { useAppServices } from 'hook/services'
import { useBrandInfo } from 'context/brand'
import axios from 'axios'
// import { AgencyContext } from '../../../context/Agency.context'

function Servicem8trigger() {
  const navigate = useNavigate()
  const { search } = useLocation()
  const code = new URLSearchParams(search).get('code')
  const state_data = new URLSearchParams(search).get('state')
  const AppServices = useAppServices()
  const [Error, setError] = useState('')
  const [brand, update] = useBrandInfo()
  const app_id = state_data.split(',')[2]
  const companyId = state_data.split(',')[1]
  const locationId = state_data.split(',')[0]
  const type = state_data.split(',')[3]

  const associate = async (creds) => {
    console.log(creds, 'creds')
    const payload = {
      code: code,
      app_id: app_id,
      companyId: companyId,
      locationId: locationId,
      type: type,
    }

    console.log(payload, 'associate payload')

    const response = await axios.post('https://marketplace.ghl.store/v1/servicem8/token', payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    console.log(response, 'associate response')
    if (response) {
      setError('App Successfully Integrated')
      setTimeout(() => {
        window.close()
      }, 2000)
    }

    if (!response) {
      return setError('Something went wrong while in associate integration, please try later')
      window.close()
    }
    // return navigate(-1)
  }

  const onLoad = () => {
    console.log(code)
    associate()
  }

  useEffect(onLoad, [])

  return (
    <MDBox>
      {!Error && <MDBox>Integrating please wait...</MDBox>}
      {Error && (
        <MDBox>
          {Error}
          {/* <MDButton onClick={() => navigate(-2)}>Go Back</MDButton> */}
        </MDBox>
      )}
    </MDBox>
  )
}

export default Servicem8trigger

import React, { useEffect } from "react";

const MapContainer = ({ locations }) => {
  useEffect(() => {
    console.log(locations, "locations");
    // Load the Google Maps API script
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDvZeBveYG3wSJr1bmdE5kJ-0C92WG2GC4&libraries=places`;
    window.document.body.appendChild(googleMapScript);

    googleMapScript.addEventListener("load", () => {
      // Initialize the map
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: {
          lat: locations[0].position.lat,
          lng: locations[0].position.lng,
        },
        zoom: 10,
      });

      // Define the locations
      //   const locations = [
      //     { name: "Bondi Beach", position: { lat: -33.890542, lng: 151.274856 } },
      //     {
      //       name: "Coogee Beach",
      //       position: { lat: -33.923036, lng: 151.259052 },
      //     },
      //     {
      //       name: "Cronulla Beach",
      //       position: { lat: -34.028249, lng: 151.157507 },
      //     },
      //     {
      //       name: "Manly Beach",
      //       position: { lat: -33.80010128657071, lng: 151.28747820854187 },
      //     },
      //     {
      //       name: "Maroubra Beach",
      //       position: { lat: -33.950198, lng: 151.259302 },
      //     },
      //   ];

      // Place markers on the map
      locations.forEach((location) => {
        new window.google.maps.Marker({
          position: location.position,
          map: map,
          title: location.name,
        });
      });
    });
  }, [locations]);

  return <div id="map" style={{ width: "100%", height: "400px" }} />;
};

export default MapContainer;

import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import QueryString from 'qs'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { ReqMethods, ghl } from 'enums'
import env from 'config'
import { useAppServices } from 'hook/services'
import { useBrandInfo } from 'context/brand'
// import { AgencyContext } from '../../../context/Agency.context'

function WinLooseDraw() {
  // client_id:cebbb890-1b44-42f8-8db0-172f06d308ff
  // client_secret:wdGxVkDXaOIjyIWYZBFl0U02
  const navigate = useNavigate()
  const { search } = useLocation()
  const code = new URLSearchParams(search).get('code')
  const state_data = new URLSearchParams(search).get('state')
  const AppServices = useAppServices()
  const [Error, setError] = useState('')
  const [brand, update] = useBrandInfo()
  // const app_id = state_data.split(',')[0]
  const companyId = state_data

  const associate = async (creds) => {
    // alert('jkgh')
    console.log(creds, 'creds')
    const payload = {
      app_id: '660ad19d02785a5a40db7447',
      companyId: companyId,
      type: 'agency app',

      // creds: creds,
      app_configuration: {
        company_id: creds.companyId,
        access_token: creds.access_token,
        refresh_token: creds.refresh_token,
        location_id: creds.locationId,
        app_id: '660ad19d02785a5a40db7447',
      },
      connection_status: 'active',
    }

    console.log(payload, 'associate payload')

    const { response } = await AppServices.services.winloosedraw.associate({
      payload,
    })
    // const { response } = await AppServices.location_apps.update({ payload })
    console.log(response, 'associate response')
    if (response) {
      setError('App Successfully Integrated')
      setTimeout(() => {
        window.close()
      }, 2000)
    }

    if (!response) {
      return setError('Something went wrong while in associate integration, please try latter')
      window.close()
    }
    // return navigate(-1)
  }
  // https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=http://localhost:3000/integrations/agency-app&client_id=65a900f68c60567ade669a2c-lrj9qqjd&scope=users.readonly locations.readonly saas/location.write saas/location.read saas/company.write saas/company.read snapshots.readonly oauth.write oauth.readonly&state=KHfjYqTRPEYlAoQ6iBbf
  const getCreds = async () => {
    const payload = {
      method: ReqMethods.POST,
      path: ghl.APIs.oauth,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      payload: QueryString.stringify({
        client_id: '660ad19d02785a5a40db7447-lvj6q5a7',
        client_secret: '52f9e285-eeed-46eb-9b8c-9cc4b3a54dcc',
        grant_type: 'authorization_code',
        code,
        refresh_token: '',
      }),
    }
    console.log(payload, 'getCreds')

    const { response } = await AppServices.services.winloosedraw.call_service({
      payload,
    })
    console.log(response, 'response')

    if (!response)
      return setError('Something went wrong while in Cred integration, please try latter')
    // if(location_id !== response.data.locationId) return setError('location ID mismatch')

    return response && associate(response.data)
  }

  const onLoad = () => {
    console.log(code)
    getCreds()
  }

  useEffect(onLoad, [])

  return (
    <MDBox>
      {!Error && <MDBox>Integrating please wait...</MDBox>}
      {Error && (
        <MDBox>
          {Error}
          <MDButton onClick={() => navigate(-2)}>Go Back</MDButton>
        </MDBox>
      )}
    </MDBox>
  )
}

export default WinLooseDraw

import React, { useEffect, useState } from 'react'
import MDTypography from 'components/MDTypography'
import { Card, CardContent, Container, Grid, TextField } from '@mui/material'
import MDButton from 'components/MDButton'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useAppServices } from 'hook/services'

function Tables() {
  const { id } = useParams()
  const [redirectUrl, setRedirectUrl] = useState(null)
  // const AppService = useAppServices();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`http://localhost:8084/v1/uiapps/sharelinks/${id}`)
        // const response = await AppService.deeplinks.GetDeeplink(id)

        console.log(response.data, 'response data')

        if (response.data && response.data.data && response.data.data.data) {
          const url = response.data.data.data.data
          console.log(url)

          setRedirectUrl(url)
        } else {
          console.log('Invalid response format:', response)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [id])

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      if (redirectUrl) {
        console.log('Redirect URL:', redirectUrl)

        window.location.replace(redirectUrl)
        window.close()
      }
    }, 1000)

    return () => clearTimeout(redirectTimeout)
  }, [redirectUrl])

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <MDTypography>Redirecting..</MDTypography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Tables

import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import Snackbar from '@mui/material/Snackbar'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

const GoHighLevel = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { locationId, companyId, app_id } = useParams()
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)

  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  })
  const { vertical, horizontal, open } = state
  const [services, setServices] = useState([])
  const [selectedCompany, setSelectedCompany] = useState('') // State to store the selected company

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading1(true)
    setLoading(true)
    const payload = {
      app_cred: {
        api_key: email,
        // password,
        teamId: selectedCompany,
      },
      location_id: locationId,
      company_id: companyId,
      app_id: app_id,
      connection_status: 'active',
    }
    console.log(payload.app_cred, 'payload')
    try {
      const response = await axios.post(
        'hhttps://marketplace.ghl.store/v1/clickup/create-credentials',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      setState({ ...state })
      console.log(response.data.teams)
      setServices(response.data.teams)
    } catch (error) {
      console.error('Error connecting:', error.message)
    } finally {
      setLoading1(false)
      setLoading(false)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundImage:
          'url("https://cdn.pixabay.com/photo/2016/09/05/15/07/concrete-1646788_1280.jpg")',
        backgroundSize: 'cover',
      }}
    >
      <Card
        sx={{
          width: '42%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <Box
          sx={{
            bgcolor: 'info.main',
            borderRadius: 'lg',
            boxShadow: 'info',
            padding: 2,
            marginBottom: 1,
          }}
        >
          <Typography variant="h4" gutterBottom style={{ color: 'white' }}>
            Connect with Clickup
          </Typography>
        </Box>

        <Typography variant="body1" sx={{ mb: 2 }}>
          To get started, enter your apiKey
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            type="text"
            label="apiKey"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
          />

          {/* <TextField
            type="text"
            label="BASE_URL"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
          /> */}

          <Button
            type="submit"
            // variant="contained"
            // color="info"
            fullWidth
            sx={{ mt: 2 }}
            style={{
              fontSize: '0.9rem',
              textTransform: 'capitalize',
              background: '#1A73E8',
              color: 'white',
            }}
          >
            Get Companies
          </Button>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message="Connected Successfully"
            key={vertical + horizontal}
          />
        </Box>

        {services.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Select
              label="Select Companies"
              value={selectedCompany}
              onChange={(e) => setSelectedCompany(e.target.value)}
              fullWidth
            >
              {services.map((service) => (
                <MenuItem key={service.id} value={service.id}>
                  {service.name}
                </MenuItem>
              ))}
            </Select>
            <Button
              type="submit"
              // variant="contained"
              // color="info"
              // fullWidth
              sx={{ mt: 2 }}
              style={{
                fontSize: '0.9rem',
                textTransform: 'capitalize',
                background: '#1A73E8',
                color: 'white',
                textAlign: 'start',
              }}
              onClick={handleSubmit}
            >
              {loading1 ? 'Connecting...' : 'Connect'}
            </Button>
          </Box>
        )}
        <Typography variant="body2" sx={{ mt: 2 }}>
          By connecting your Clickup account, you authorize the integration with GHL.
        </Typography>
      </Card>
    </div>
  )
}

export default GoHighLevel

import React, { useState, useEffect } from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'

const Data = () => {
  const [data, setData] = useState([])
  const [copiedStates, setCopiedStates] = useState([])
  const AppService = useAppServices()

  const fetchData = async () => {
    try {
      const { response } = await AppService.qrcode.Get()
      console.log('API Response:', response)

      if (response) {
        setData(response || [])
        setCopiedStates(new Array(response.length).fill(false))
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const onLoad = () => {
    fetchData()
  }

  useEffect(() => {
    onLoad()
  }, [])

  const handleCopy = (index) => {
    try {
      const newCopiedStates = new Array(copiedStates.length).fill(false)
      newCopiedStates[index] = true
      setCopiedStates(newCopiedStates)

      const dataToCopy = data[index]?.qrcodeUrl
      // navigator.clipboard.writeText(dataToCopy);

      const qrcode_id = data[index]?._id
      const url = data[index]?.qrcodeUrl

      console.log('qrcode_id:', qrcode_id)

      var newTabUrl = `http://localhost:3000/qrcode/redirect/${qrcode_id}`
      console.log(newTabUrl, 'newTabUrl')
      window.open(newTabUrl, '_blank')
      const redirectToUrl = data[index]?.qrcodeUrl

      console.log('Redirecting to:', redirectToUrl)

      window.open(redirectToUrl, '_blank')
    } catch (error) {
      console.error('Error copying to clipboard:', error)
    }
  }

  return {
    handleRefresh: fetchData,
    columns: [
      { Header: 'text', accessor: 'text', align: 'left' },
      { Header: 'size', accessor: 'size', align: 'left' },
      {
        Header: 'copy',
        accessor: 'copy',
        align: 'center',
        Cell: ({ row }) => (
          <CopyToClipboard text={row.original.qrcodeUrl} onCopy={() => handleCopy(row.index)}>
            <MDButton style={{ background: 'whitesmoke', color: 'black' }}>Get</MDButton>
          </CopyToClipboard>
        ),
      },
    ],

    rows: data?.map((item, index) => ({
      text: (
        <MDTypography component="h3" variant="caption" color="black" fontWeight="medium">
          {item?.text}
        </MDTypography>
      ),
      size: (
        <MDTypography component="h3" variant="caption" color="black" fontWeight="medium">
          {item?.size === '150'
            ? 'Small'
            : item?.size === '300'
            ? 'Medium'
            : item?.size === '450'
            ? 'Large'
            : 'Unknown Size'}
        </MDTypography>
      ),
      copy: (
        <CopyToClipboard text={item?.qrcodeUrl} onCopy={() => handleCopy(index)}>
          <MDButton style={{ color: 'black' }}>Copy</MDButton>
        </CopyToClipboard>
      ),
    })),
  }
}

export default Data

import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import { useLocation, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import Snackbar from '@mui/material/Snackbar'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

const GoHighLevel = () => {
  const [secretToken, setSecretToken] = useState('')
  const [password, setPassword] = useState('')
  const { locationId, companyId, app_id } = useParams()
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [generatedUrl, setGeneratedUrl] = useState('')
  const [showCopyButton, setShowCopyButton] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const { search } = useLocation()

  const type = new URLSearchParams(search).get('type')
  const [copy, setCopy] = useState(false)

  const [formData, setFormData] = useState({
    // name: '',
    // trggerType: '',
    locationId: locationId,
    company_id: companyId,
    app_id: app_id,
    type: type,
  })

  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  })

  const { vertical, horizontal, open } = state
  const [selectedCompany, setSelectedCompany] = useState('') // State to store the selected company

  const handleClose = () => {
    setState({ ...state, open: false })
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const copy_url_response = await axios.post(
        'https://marketplace.ghl.store/v1/drchronotrigger/copy-url',
        formData
      ) // Call your backend API
      setGeneratedUrl(copy_url_response.data.data)
      setShowCopyButton(true)
      setState({ ...state })
    } catch (error) {
      console.error('Error connecting:', error.message)
    } finally {
      setLoading1(false)
      setLoading(false)
    }
  }
  const handleCopyUrl = () => {
    const text = generatedUrl

    // Create a temporary textarea element to hold the text
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.setAttribute('readonly', '')
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'

    // Append the textarea to the body
    document.body.appendChild(textarea)

    // Select the text inside the textarea
    textarea.select()

    // Execute the copy command
    document.execCommand('copy')
    setCopy(true)
    // Remove the textarea from the DOM
    document.body.removeChild(textarea)
    setSnackbarOpen(true)

    setTimeout(() => {
      setCopy(false)
    }, 2000)
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundImage:
          'url("https://cdn.pixabay.com/photo/2016/09/05/15/07/concrete-1646788_1280.jpg")',
        backgroundSize: 'cover',
      }}
    >
      <Card
        sx={{
          width: '42%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <Box
          sx={{
            bgcolor: 'info.main',
            borderRadius: 'lg',
            boxShadow: 'info',
            padding: 2,
            marginBottom: 1,
          }}
        >
          <Typography variant="h4" gutterBottom style={{ color: 'white' }}>
            Connect with Drchrono Webhook
          </Typography>
        </Box>

        <Typography variant="body1" sx={{ mb: 2 }}>
          To get started, enter your Webhook Secret Token
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          {/* <TextField
            type="text"
            label="Webhook Secret Token"
            value={secretToken}
            onChange={(e) => setSecretToken(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
          /> */}

          {/* <TextField
            type="text"
            label="BASE_URL"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
          /> */}

          {/* Copy URL Button - Show only if URL is generated */}

          {/* <MDTypography variant="body1" sx={{ mt: 2 }}>
              Generated URL: {generatedUrl}
            </MDTypography> */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleCopyUrl}
            sx={{ mt: 2 }}
            fullWidth
            style={{ color: 'white' }}
            type="submit"
          >
            Copy URL
          </Button>
          <Typography style={{ textAlign: 'center', fontSize: '0.9rem', color: 'red' }}>
            Important Note: Copy the url and add this in your drchrono account webhook url
          </Typography>
          {/* Snackbar for Copy Confirmation */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
            message="URL copied to clipboard"
          />
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message="Connected Successfully"
            key={vertical + horizontal}
          />
        </Box>
      </Card>
    </div>
  )
}

export default GoHighLevel

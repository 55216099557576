import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Box,
  Snackbar,
} from '@mui/material'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import axios from 'axios'
import { useParams, useLocation } from 'react-router-dom'

const GoHighLevel = () => {
  const { locationId, company_id, app_id } = useParams()
  const { search } = useLocation()
  const type = new URLSearchParams(search).get('type')

  // Store form data
  const [formData, setFormData] = useState({
    event: '',
    locationId: locationId,
    company_id: company_id,
    app_id: app_id,
    type: type,
  })

  // Store lead sources
  const [leadSourceDetails, setLeadSourceDetails] = useState([])

  const [generatedUrl, setGeneratedUrl] = useState('')
  const [showCopyButton, setShowCopyButton] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [copy, setCopy] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post('http://localhost:8082/v1/acculynx/copy-url', formData) // Call your backend API

      // Extract leadSource details
      const leads = response.data.data.items.map((item) => ({
        leadSourceId: item.leadSource.id,
        leadSourceName: item.leadSource.name,
      }))

      console.log(leads, 'Lead Source Details')

      setLeadSourceDetails(leads) // Update the state with lead sources
      setGeneratedUrl(response.data.data)
      setShowCopyButton(true)
    } catch (error) {
      console.error('Error generating URL:', error)
    }
  }

  const handleCopyUrl = () => {
    const text = generatedUrl
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.setAttribute('readonly', '')
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'

    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    setCopy(true)
    document.body.removeChild(textarea)
    setSnackbarOpen(true)

    setTimeout(() => {
      setCopy(false)
    }, 2000)
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Card
        sx={{
          width: '32%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <MDTypography variant="h4" gutterBottom mb={3}>
          Create Webhook Acculynx
        </MDTypography>
        <MDBox component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <FormControl fullWidth style={{ marginBottom: '1rem' }}>
            <InputLabel id="event-label">Leads</InputLabel>
            <Select
              labelId="event-label"
              name="event"
              value={formData.event}
              onChange={handleChange}
              label="Leads"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {/* Dynamically populate lead source names in the dropdown */}
              {leadSourceDetails.map((lead) => (
                <MenuItem key={lead.leadSourceId} value={lead.leadSourceId}>
                  {lead.leadSourceName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Submit Button */}
          <div style={{ display: 'flex' }}>
            <Button variant="contained" type="submit" style={{ color: 'white', marginTop: '1rem' }}>
              Get Leads
            </Button>
          </div>
        </MDBox>

        {/* Copy URL Button - Show only if URL is generated */}
        {showCopyButton && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCopyUrl}
              sx={{ mt: 2 }}
              fullWidth
              style={{ color: 'white' }}
            >
              Copy URL
            </Button>
          </>
        )}

        {/* Snackbar for Copy Confirmation */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message="URL copied to clipboard"
        />
      </Card>
    </div>
  )
}

export default GoHighLevel

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'
import Loader from 'examples/Loader'
import MUIDataTable from 'mui-datatables'
import { Switch } from '@mui/material'
import Swal from 'sweetalert2'

function GoHighLevel() {
  const AppService = useAppServices()
  const { companyId, app_id } = useParams()
  const [message, setMessage] = useState()
  const [loader, setLoader] = useState(true)
  const [locations, setLocations] = useState([])
  const [appToken, setappToken] = useState({})

  const handleCopy = () => {
    const text = `https://alltheapps.io/app/${app_id}/${companyId}/{{location.id}}?sessionKey={{user.sessionKey}}`

    // Create a temporary textarea element to hold the text
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.setAttribute('readonly', '')
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'

    // Append the textarea to the body
    document.body.appendChild(textarea)

    textarea.select()

    document.execCommand('copy')

    document.body.removeChild(textarea)
  }
  const columns = [
    {
      name: 'Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'install_status',
      options: {
        filter: false,
      },
    },
  ]

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none',
    filterType: 'multiselect',
    responsive: 'standard',
    rowsPerPage: 500,
    rowsPerPageOptions: [100, 300, 500],
  }
  const handleLocation = async (value, locationId, activeLocations) => {
    if (activeLocations.length == 20) {
      if (value == false) {
        const payload = {
          install_status: value,
          companyId: companyId,
          app_id: app_id,
          locationId: locationId,
        }
        const { response } = await AppService.webhook.update_app_token({
          payload: payload,
        })
        if (response) {
          getLocations()
        }
      } else {
        document.getElementById(locationId).checked = false
        Swal.fire({
          title: 'Limit Exceed',
          text: 'Your Location Limit is full',
          showConfirmButton: true,
          confirmButtonText: 'Okay',
        })
      }
    } else {
      const payload = {
        install_status: value,
        companyId: companyId,
        app_id: app_id,
        locationId: locationId,
      }
      const { response } = await AppService.webhook.update_app_token({
        payload: payload,
      })
      if (response) {
        getLocations()
      }
    }
  }
  const getLocations = async () => {
    const { response } = await AppService.webhook.get_locations({
      query: `company_id=${companyId}&app_id=${app_id}`,
    })
    console.log(response, 'getLocationsgetLocations')
    if (response) {
      const tabel_data = []
      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index]
        const activeLocations = response.app_token?.activeLocations
        const temp = [
          element.name,
          <>
            <Switch
              id={element.id}
              defaultChecked={
                activeLocations.filter((item) => item.includes(element.id))[0] ? true : false
              }
              onClick={(e) => handleLocation(e.target.checked, element.id, activeLocations)}
            />
          </>,
        ]
        tabel_data.push(temp)
      }
      setLocations(tabel_data)
      setappToken(response.app_token)
      setLoader(false)
    } else {
      setLoader(false)
    }
  }
  const onLoad = () => {
    getLocations()
  }
  useEffect(() => {
    onLoad()
  }, [])
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: appToken.type == 'limited' ? 'unset' : 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
        gap: '2rem',
      }}
    >
      {loader ? (
        <Loader />
      ) : (
        <>
          {appToken.type == 'limited' ? (
            <>
              <MDBox width="90%" display="flex" justifyContent="flex-end" mt={4}>
                <MDButton
                  onClick={handleCopy}
                  type="button"
                  variant="contained"
                  color="info"
                  style={{ fontSize: '0.9rem', textTransform: 'capitalize' }}
                >
                  Copy Custom Menu Link
                </MDButton>
              </MDBox>
              <MDBox width="90%">
                <MUIDataTable
                  title={`Locations(${locations.length}/${appToken.activeLocations.length})`}
                  data={locations}
                  columns={columns}
                  options={options}
                />
              </MDBox>
            </>
          ) : (
            <Card
              sx={{
                width: '40%',
                textAlign: 'center',
                padding: '2rem',
                borderRadius: '29px',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              }}
            >
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                p={2}
                mb={1}
              >
                <MDTypography
                  variant="h6"
                  gutterBottom
                  style={{ color: 'white', fontSize: '14px' }}
                >
                  Connect with Custom Menu Links
                </MDTypography>
              </MDBox>
              <MDBox style={{ margin: '8rem' }}></MDBox>

              <MDButton
                onClick={handleCopy}
                type="button"
                variant="contained"
                color="info"
                fullWidth
                sx={{ mt: 2 }}
                style={{ fontSize: '0.9rem', textTransform: 'capitalize' }}
              >
                Copy
              </MDButton>
              <MDTypography variant="button" color="success" sx={{ mt: 1 }}>
                {message}
              </MDTypography>
            </Card>
          )}
        </>
      )}
    </div>
  )
}

export default GoHighLevel

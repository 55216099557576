import MDButton from 'components/MDButton'
import AddBoxIcon from '@mui/icons-material/AddBox'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Snackbar } from '@mui/material'
const CustomlinkModal = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const searchParams = new URLSearchParams(document.location.search)
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  })
  const { vertical, horizontal, open } = state

  const handleClose = () => {
    setState({ ...state, open: false })
  }
  const sessionkey = searchParams.get('sessionkey')

  const auth_url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=https://app.alltheapps.io/integrations/winloosedraw&client_id=660ad19d02785a5a40db7447-lvj6q5a7&scope=businesses.readonly businesses.write calendars.readonly calendars.write calendars/events.readonly calendars/events.write calendars/groups.readonly calendars/groups.write campaigns.readonly conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write links.readonly links.write locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly&state=&sessionKey=${sessionkey}`

  function AddProduct({ open, onClose }) {
    return (
      <MDModal open={open} onClose={onClose} width={'auto'} height={'auto'}>
        <MDBox>
          <iframe src={auth_url} width={500} height={500}></iframe>
        </MDBox>
      </MDModal>
    )
  }
  const closeAddProduct = (subscription) => {
    setOpenAddProduct(false)
  }
  const handleOpen = () => {
    if (sessionkey != null && sessionkey != '') {
      console.log('session key not provideded')
      setOpenAddProduct(true)
    } else {
      setState({ ...state, open: true })
    }
  }
  return (
    <>
      <MDButton variant="contained" color="info" onClick={handleOpen}>
        Connect with Win Loose Draw
      </MDButton>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message="Something went wrong please refresh page"
        key={vertical + horizontal}
      />
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  )
}

export default CustomlinkModal

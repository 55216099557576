import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import MDBox from 'components/MDBox'
import axios from 'axios'
// import { AgencyContext } from '../../../context/Agency.context'

function Wrike() {
  const navigate = useNavigate()
  const { search } = useLocation()
  const code = new URLSearchParams(search).get('code')
  const state_data = new URLSearchParams(search).get('state')
  const [Error, setError] = useState('')
  const app_id = state_data.split(',')[2]
  const companyId = state_data.split(',')[1]
  const locationId = state_data.split(',')[0]
  // const clientId = state_data.split(',')[4]
  // const clientSecret = state_data.split(',')[5]
  const type = state_data.split(',')[3]
  // console.log('webhooksecrettoken:', webhookSecretToken)
  const associate = async (creds) => {
    console.log(creds, 'creds')
    const payload = {
      code: code,
      app_id: app_id,
      companyId: companyId,
      locationId: locationId,
      type: type,
    }

    console.log(payload, 'payload secret token')

    const response = await axios.post('https://marketplace.ghl.store/v1/wrike/token', payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    console.log(response, 'associate response')
    if (response) {
      setError('App Successfully Integrated')
      // setTimeout(() => {
      //   window.close()
      // }, 2000)
    }

    if (!response) {
      return setError('Something went wrong while in associate integration, please try later')
    }
  }

  const onLoad = () => {
    console.log(code)
    associate()
  }

  useEffect(onLoad, [])

  return (
    <MDBox>
      {!Error && <MDBox>Integrating please wait...</MDBox>}
      {Error && (
        <MDBox>
          {Error}
          {/* <MDButton onClick={() => navigate(-2)}>Go Back</MDButton> */}
        </MDBox>
      )}
    </MDBox>
  )
}

export default Wrike

import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import { useLocation, useParams } from 'react-router-dom'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import { Snackbar } from '@mui/material'
const GoHighLevel = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [userData, setuserData] = useState({})
  const { locationId, companyId, app_id } = useParams()
  const [loading, setLoading] = useState(false)
  const { search } = useLocation()
  const type = new URLSearchParams(search).get('type')
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  })
  const { vertical, horizontal, open } = state

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    const payload = {
      app_cred: {
        email,
        password,
      },
      location_id: locationId, //kEyKMducTgZLJcLFgw27
      company_id: companyId, //6NKdTpPe08P8DR5Jg7k6
      app_id: app_id,
      connection_status: 'active',
      type: type,
    }

    try {
      const response = await axios.post(
        // 'http://localhost:8084/v1/trello/create-credentials',
        'https://marketplace.ghl.store/v1/trello/create-credentials',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      setState({ ...state, open: true })
      console.log(response.data)
    } catch (error) {
      console.error('Error connecting:', error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundImage:
          'url("https://cdn.pixabay.com/photo/2016/09/05/15/07/concrete-1646788_1280.jpg")',
        backgroundSize: 'cover',
      }}
    >
      <Card
        sx={{
          width: '42%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          p={2}
          mb={1}
        >
          <MDTypography variant="h4" gutterBottom style={{ color: 'white' }}>
            Connect with Trello
          </MDTypography>
        </MDBox>

        <MDTypography variant="body1" sx={{ mb: 2 }}>
          To get started, enter your apiKey and token below and click "Connect."
        </MDTypography>

        <MDBox component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            type="text"
            label="API KEY"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
          />

          <TextField
            type="text"
            label="TOKEN"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
          />

          <MDButton
            type="submit"
            variant="contained"
            color="info"
            fullWidth
            sx={{ mt: 2 }}
            style={{ fontSize: '0.9rem', textTransform: 'capitalize' }}
          >
            {loading ? 'Connecting...' : 'Connect'}
          </MDButton>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message="Connected Successfully"
            key={vertical + horizontal}
          />
        </MDBox>

        {/* <MDTypography variant="body2" sx={{ mt: 2 }}>
          By connecting your Trello account, you authorize the integration with GHL.
        </MDTypography> */}
      </Card>
    </div>
  )
}

export default GoHighLevel

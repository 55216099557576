/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react'

// react-router-dom components
import {
  Link,
  useLocation,
  useParams,
  // useNavigate
} from 'react-router-dom'

// @mui material components
import Card from '@mui/material/Card'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import { useAppServices } from 'hook/services'

import IframeModal from './components/modal'
import MDButton from 'components/MDButton'

// import { Button } from '@mui/material'

function GoHighLevel() {
  // const [rememberMe, setRememberMe] = useState(false)

  // const handleSetRememb  erMe = () => setRememberMe(!rememberMe)
  const Service = useAppServices()
  const [message, setMessage] = useState()
  // const [userData, setuserData] = useState({})
  const auth_url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=http://localhost:3000/integrations/messagebird&client_id=652e65a3d8698874870e6913-lvktycl4&scope=businesses.readonly businesses.write calendars.readonly calendars.write calendars/events.readonly calendars/events.write calendars/groups.readonly calendars/groups.write campaigns.readonly conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write links.readonly links.write locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly&state=`
  const handleSubmit = () => {
    var windowFeatures = 'width=600,height=600,menubar=no,location=no,toolbar=no,scrollbars=yes'
    window.open(auth_url, '_blank', windowFeatures)
  }

  return (
    // <BasicLayout image={bgImage} type="notopbar">
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Card
        sx={{
          width: '40%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          p={2}
          mb={1}
        >
          <MDTypography variant="h5" gutterBottom style={{ color: 'white' }}>
            Connect with MessageBird
          </MDTypography>
        </MDBox>
        <MDBox style={{ margin: '8rem' }}></MDBox>
        {/* <IframeModal /> */}

        <MDButton
          onClick={handleSubmit} // Call handleConnectClick function when button is clicked
          type="submit"
          variant="contained"
          color="info"
          fullWidth
          sx={{ mt: 2 }}
          style={{ fontSize: '0.9rem', textTransform: 'capitalize' }}
        >
          Connect
        </MDButton>
        <MDTypography variant="button" color="error" sx={{ mt: 1 }}>
          {message}
        </MDTypography>
      </Card>
    </div>
    // </BasicLayout>
  )
}

export default GoHighLevel

import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import QueryString from 'qs'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { ReqMethods, ghl } from 'enums'
import env from 'config'
import { useAppServices } from 'hook/services'
import { useBrandInfo } from 'context/brand'
import axios from 'axios'
// import { AgencyContext } from '../../../context/Agency.context'

function Spreadsheet() {
  // client_id:cebbb890-1b44-42f8-8db0-172f06d308ff
  // client_secret:wdGxVkDXaOIjyIWYZBFl0U02
  const navigate = useNavigate()
  const { search } = useLocation()
  const code = new URLSearchParams(search).get('code')
  const state_data = new URLSearchParams(search).get('state')
  const AppServices = useAppServices()
  const [Error, setError] = useState('')
  const [brand, update] = useBrandInfo()
  const location_id = state_data.split(',')[0]
  const companyId = state_data.split(',')[1]

  const associate = async () => {
    const response = await AppServices.spreadsheet.getToken({
      query: `code=${code}&location_id=${location_id}&companyId=${companyId}`,
    })

    console.log(response, 'associate response')
    if (response) {
      setError('App Successfully Integrated')
      setTimeout(() => {
        // window.close()
      }, 2000)
    }

    if (!response) {
      return setError('Something went wrong while in associate integration, please try latter')
    }
  }
  const onLoad = () => {
    console.log(code)
    associate()
  }

  useEffect(onLoad, [])

  return (
    <MDBox>
      {!Error && <MDBox>Integrating please wait...</MDBox>}
      {Error && (
        <MDBox>
          {Error}
          <MDButton onClick={() => navigate(-2)}>Go Back</MDButton>
        </MDBox>
      )}
    </MDBox>
  )
}

export default Spreadsheet

// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import env from 'config'
// import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React from 'react'
import { Alert, Grid } from '@mui/material'
import Loader from 'examples/Loader'
import MUIDataTable from 'mui-datatables'
// import "../Users/style.css";

import { useEffect, useState } from 'react'
import { useAgencyInfo } from 'context/agency'
import { useAppServices } from 'hook/services'
import MDButton from 'components/MDButton'
import { useNavigate, useParams } from 'react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import MDBox from 'components/MDBox'
import CalenderModal from './components/CalenderModal'
function Calender() {
  const navigate = useNavigate()
  const params = useParams()
  const [agency] = useAgencyInfo()
  const Service = useAppServices()
  const [Conjo, setConjo] = useState([])
  const [CalendersData, setCalendersData] = useState([])
  const [processing, setProcessing] = useState(false)
  const [copy, setcopy] = useState(false)
  const app_id = '653a8ca5623dcf63fb0740d9'
  const handlecopy = () => {
    setcopy(true)
    setTimeout(() => {
      setcopy(false)
    }, 2000)
  }
  const columns = [
    {
      name: 'Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'Created At',
      options: {
        filter: false,
      },
    },
    {
      name: 'Action',
      options: {
        filter: false,
      },
    },
  ]

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none',
    filterType: 'multiselect',
    responsive: 'standard',
  }
  const getCalendars = async () => {
    const { response } = await Service.calnedermaps.filter({
      query: `location_id=${params.locationId}&app_id=${app_id}`,
    })
    console.log(response, 'getCalendars')
    if (response) {
      const temp_cal = []
      var tabel_data = []
      response.data.forEach((element) => {
        const temp = [
          element.name,
          element.createdAt,
          <>
            <CopyToClipboard
              text={`https://agency.levelupmarketplace.io/${params.locationId}/calender/${element._id}`}
              onCopy={handlecopy}
              ml={2}
            >
              <MDButton variant="contained" color="info" size="small" ml={2}>
                {copy ? 'Copied' : 'Copy'}
              </MDButton>
            </CopyToClipboard>
          </>,
        ]
        tabel_data.push(temp)
      })
      setConjo(tabel_data)
      for (let index = 0; index < response.ghlCalenders.length; index++) {
        const element = response.ghlCalenders[index]
        temp_cal.push({
          label: element.name,
          value: element.id,
        })
      }
      setCalendersData(temp_cal)
      setProcessing(false)
    } else {
      setProcessing(false)
    }
  }
  // "TtzMWtV2AxjW6LLcxafM"
  const onLoad = () => {
    getCalendars()
  }
  useEffect(async () => {
    onLoad()
  }, [])
  return (
    <>
      {processing ? (
        <Loader />
      ) : (
        <MDBox p={6}>
          <MDBox display="flex" justifyContent="flex-end" mb={1}>
            <CalenderModal CalendersData={CalendersData} handleRefresh={getCalendars} />
          </MDBox>
          {/* <DashboardNavbar type="calender_page" handleChange={onLoad} data={data} /> */}
          <Grid container>
            <Grid item xs={12}>
              <MUIDataTable title={'Calendar'} data={Conjo} columns={columns} options={options} />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </>
  )
}

export default Calender

import React, { useState } from 'react';
import MDModal from 'components/MDModal';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import axios from 'axios';
import PropTypes from 'prop-types';
import MDBox from 'components/MDBox';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useAppServices } from 'hook/services';

function CreateDeeplink({ open, onClose, handleRefresh }) {
  const AppServices = useAppServices();


  const [profileUsername, setProfileUsername] = useState('');
  const [postName, setPostName] = useState('');
  const [channelName, setChannelName] = useState('');
  const [postId, setPostId] = useState('');
  const [videoId, setVideoId] = useState('');
  const [type, setType] = useState('');

  const handleSubmit = async () => {
    try {
      let dataToSend;

      switch (type) {
        case 'pinterest':
          dataToSend = { profileUsername, postName };
          break;
        case 'twitter':
          dataToSend = { channelName, postId };
          break;
        case 'instagram':
          dataToSend = { post_url: postName };
          break;
        case 'youtube':
          dataToSend = { videoId };
          break;
        case 'tiktok':
          dataToSend = { videoId, username: profileUsername };
          break;
        default:
          break;
      }

      const response = await axios.post('http://localhost:8084/v1/uiapps/sharelinks/create', {
        type,
        data: dataToSend,
      });

      const responseData = response.data.data;
      console.log(responseData);
      onClose();
      handleRefresh();
    } catch (error) {
      console.error('Error', error);
    }
  };


  return (
    <MDModal open={open} onClose={onClose}>
      <MDBox>
        <MDTypography variant="h5" sx={{ paddingBottom: 4 }}>
          Sharelinks
        </MDTypography>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel>Type</InputLabel>
          <Select value={type} onChange={(e) => setType(e.target.value)}>
            <MenuItem value="instagram">Instagram</MenuItem>
            <MenuItem value="youtube">YouTube</MenuItem>
            <MenuItem value="tiktok">TikTok</MenuItem>
            <MenuItem value="pinterest">Pinterest</MenuItem>
            <MenuItem value="twitter">Twitter</MenuItem>
          </Select>
        </FormControl>
        {type === 'pinterest' ? (
          <>
            <MDInput
              label="Pinterest Username"
              value={profileUsername}
              fullWidth
              onChange={(e) => setProfileUsername(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <MDInput
              label="Post Name"
              value={postName}
              fullWidth
              onChange={(e) => setPostName(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
          </>
        ) : type === 'twitter' ? (
          <>
            <MDInput
              label="Twitter Channel Name"
              value={channelName}
              fullWidth
              onChange={(e) => setChannelName(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <MDInput
              label="Twitter Post ID"
              value={postId}
              fullWidth
              onChange={(e) => setPostId(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
          </>
        ) : type === 'instagram' ? (
          <MDInput
            label="Instagram Post URL"
            value={postName}
            fullWidth
            onChange={(e) => setPostName(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
        ) : type === 'youtube' ? (
          <MDInput
            label="YouTube Video ID"
            value={videoId}
            fullWidth
            onChange={(e) => setVideoId(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
        ) : type === 'tiktok' ? (
          <>
            <MDInput
              label="TikTok Video ID"
              value={videoId}
              fullWidth
              onChange={(e) => setVideoId(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <MDInput
              label="TikTok Username"
              value={profileUsername}
              fullWidth
              onChange={(e) => setProfileUsername(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
          </>
        ) : null}
        <Button onClick={handleSubmit} variant="contained" style={{ color: 'white' }}>
          Create
        </Button>
      </MDBox>
    </MDModal>
  );
}

CreateDeeplink.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
};

export default CreateDeeplink;

import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import { useParams } from 'react-router-dom'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import { useAppServices } from 'hook/services'

const GoHighLevel = () => {
  const { locationId, companyId } = useParams()
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState('')
  // 653a86bbf2fa407fc09b2886

  const handleConnectClick = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`http://localhost:8082/v1/spreadsheet/get-token`)
      console.log(response.data)

      const authorizationUrl = `${response.data.data}&state=${locationId},${companyId}`
      window.open(authorizationUrl, '_blank')
    } catch (error) {
      console.error('Error:', error)
      // Handle error
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundImage:
          'url("https://cdn.pixabay.com/photo/2016/09/05/15/07/concrete-1646788_1280.jpg")',
        backgroundSize: 'cover',
      }}
    >
      <Card
        sx={{
          width: '22%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          p={2}
          mb={1}
        >
          <MDTypography variant="h6" gutterBottom style={{ color: 'white' }}>
            Connect with Google Sheets
          </MDTypography>
        </MDBox>
        <MDBox style={{ margin: '2rem' }}></MDBox>

        <MDButton
          onClick={handleConnectClick} // Call handleConnectClick function when button is clicked
          type="submit"
          variant="contained"
          color="info"
          fullWidth
          sx={{ mt: 2 }}
          style={{ fontSize: '0.9rem', textTransform: 'capitalize' }}
        >
          {loading ? 'Connecting...' : 'Connect'}
        </MDButton>
      </Card>
    </div>
  )
}

export default GoHighLevel

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
// import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
// import Footer from 'examples/Footer'
import DataTable from 'examples/Tables/DataTable';

// Data
import authorsTableData from './data/authorsTableData';
import MDButton from 'components/MDButton';
import CreateDeeplink from './components/CreateDeeplink';
import { useState } from 'react';

function Sharelink() {
  const { columns, rows ,handleRefresh} = authorsTableData()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleSelectClick = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }
  return (
    <>
      {/* <DashboardNavbar /> */}
<MDBox pt={6} pb={3} style={{ display: 'flex', justifyContent: 'center' }}>
  <Grid container spacing={3} xs={8}>
    <Grid item xs={12}>
      <Card>
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <MDBox>
            <MDTypography variant="h6" color="white">
              Share Links
            </MDTypography>
          </MDBox>

          <MDButton onClick={handleSelectClick} style={{ marginLeft: '10px' }}>
            Create
          </MDButton>

          <CreateDeeplink open={isModalOpen} onClose={handleCloseModal} handleRefresh={handleRefresh} />
        </MDBox>
        <MDBox pt={3}>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
        </MDBox>
      </Card>
    </Grid>
  </Grid>
</MDBox>

      {/* <Footer /> */}
    </>
  )
}

export default Sharelink

import React, { useState, useEffect } from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDAvatar from 'components/MDAvatar'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { useAppServices } from 'hook/services'
import MDButton from 'components/MDButton'

const Data = () => {
  const [data, setData] = useState([])
  const [copiedStates, setCopiedStates] = useState([])

  const AppService = useAppServices()

  const fetchData = async () => {
    try {
      const { response } = await AppService.sharelinks.Get()
      console.log(response, 'fetchData')
      console.log(response.data, 'id')
      if (response) {
        setData(response?.data)
        setCopiedStates(new Array(response.data.length).fill(false))
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const onLoad = () => {
    fetchData()
  }

  useEffect(() => {
    onLoad()
  }, [])

  const handleCopy = (index) => {
    try {
      const newCopiedStates = new Array(copiedStates.length).fill(false)
      newCopiedStates[index] = true
      setCopiedStates(newCopiedStates)

      const dataToCopy = data[index]?.data?.data
      // navigator.clipboard.writeText(dataToCopy)

      const deeplinkId = data[index]?._id
      const url = data[index]?.data.data

      console.log('deeplinkId:', deeplinkId)

      var newTabUrl = `http://localhost:3000/sharelinks/redirect/${deeplinkId}`
      console.log(newTabUrl, 'urll')
      window.open(newTabUrl, '_blank')
      const redirectToUrl = data[index]?.data?.data
      console.log('Redirecting to:', redirectToUrl)

      window.open(redirectToUrl, '_blank')
    } catch (error) {
      console.error('Error copying to clipboard:', error)
    }
  }

  return {
    handleRefresh: fetchData,
    columns: [
      { Header: 'deeplink', accessor: 'deeplink', align: 'left' },
      { Header: 'data', accessor: 'data', align: 'left' },
      {
        Header: 'copy',
        accessor: 'copy',
        align: 'center',
        Cell: ({ row }) => (
          <CopyToClipboard text={row.original.data.data} onCopy={() => handleCopy(row.index)}>
            <MDButton style={{ background: 'whitesmoke', color: 'black' }}>Get</MDButton>
          </CopyToClipboard>
        ),
      },
    ],

    rows: data?.map((item, index) => ({
      deeplink: (
        <MDTypography component="h3" variant="caption" color="black" fontWeight="medium">
          {item?.type}
        </MDTypography>
      ),
      data: (
        <MDTypography component="h3" variant="caption" color="black" fontWeight="medium">
          {item?.data?.data}
        </MDTypography>
      ),
      copy: (
        <CopyToClipboard text={item?.data?.data} onCopy={() => handleCopy(index)}>
          <MDButton style={{ color: 'black' }}>Copy</MDButton>
        </CopyToClipboard>
      ),
    })),
  }
}

export default Data

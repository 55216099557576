import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'
import { useLocation, useParams } from 'react-router-dom'

import { Snackbar } from '@mui/material'
const GoHighLevel = () => {
  const { locationId, companyId, app_id } = useParams()
  const [buttonText, setButtonText] = useState('Connect')
  const { search } = useLocation()
  const type = new URLSearchParams(search).get('type')

  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  })
  const { vertical, horizontal, open } = state

  const handleClose = () => {
    setState({ ...state, open: false })
  }
  //read_attachments
  const auth_url = `https://go.servicem8.com/oauth/authorize?response_type=code&client_id=620032&scope=read_customers%20manage_customers%20read_jobs%20manage_jobs%20create_jobs%20read_job_contacts%20manage_job_materials%20read_job_categories%20manage_job_categories%20read_job_materials%20read_job_queues%20manage_job_contacts%20manage_job_queues%20read_customer_contacts%20manage_customer_contacts%20read_job_notes%20publish_job_notes%20read_job_photos%20publish_job_photos%20read_job_attachments	%20manage_inventory%20read_tasks%20manage_tasks%20manage_schedule%20read_schedule%20read_job_attachments%20publish_job_attachments%20read_attachments%20staff_locations%20staff_activity%20vendor%20read_inventory%20vendor_logo%20vendor_email%20manage_locations%20read_staff%20manage_staff%20read_locations%20publish_sms%20manage_templates%20publish_email%20read_assets%20manage_assets%20manage_badges&redirect_uri=https://app.alltheapps.io/integrations/servicem8&state=${locationId},${companyId},${app_id},${type}`

  const handleSubmit = (event) => {
    event.preventDefault()
    window.open(auth_url, '_blank')
    setButtonText('Connected')
    setState({ ...state, open: true })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundImage:
          'url("https://cdn.pixabay.com/photo/2016/09/05/15/07/concrete-1646788_1280.jpg")',
        backgroundSize: 'cover',
      }}
    >
      <Card
        sx={{
          width: '42%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          p={2}
          mb={1}
        >
          <MDTypography variant="h4" gutterBottom style={{ color: 'white' }}>
            Connect with ServiceM8
          </MDTypography>
        </MDBox>

        <MDBox component="form" onSubmit={handleSubmit} sx={{ mt: 15 }}>
          <MDButton
            type="submit"
            variant="contained"
            color="info"
            fullWidth
            sx={{ mt: 2 }}
            style={{ fontSize: '0.9rem', textTransform: 'capitalize' }}
          >
            {buttonText}
          </MDButton>

          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message="Connected Successfully"
            key={vertical + horizontal}
          />
        </MDBox>
      </Card>
    </div>
  )
}

export default GoHighLevel

import React, { useState } from 'react'
import Card from '@mui/material/Card'
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Box,
  Snackbar,
} from '@mui/material'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import axios from 'axios'
import { useParams, useLocation } from 'react-router-dom'

const GoHighLevel = () => {
  const { locationId, company_id, app_id } = useParams()
  const { search } = useLocation()
  const type = new URLSearchParams(search).get('type')
  const [formData, setFormData] = useState({
    // name: '',
    // trggerType: '',
    event: '',
    locationId: locationId,
    company_id: company_id,
    app_id: app_id,
    type: type,
  })
  const [generatedUrl, setGeneratedUrl] = useState('')
  const [showCopyButton, setShowCopyButton] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [copy, setCopy] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(
        'https://marketplace.ghl.store/v1/jobnimbusTrigger/copy-url',
        formData
      ) // Call your backend API
      setGeneratedUrl(response.data.data)
      setShowCopyButton(true)
    } catch (error) {
      console.error('Error generating URL:', error)
    }
  }
  const handleCopyUrl = () => {
    const text = generatedUrl

    // Create a temporary textarea element to hold the text
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.setAttribute('readonly', '')
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'

    // Append the textarea to the body
    document.body.appendChild(textarea)

    // Select the text inside the textarea
    textarea.select()

    // Execute the copy command
    document.execCommand('copy')
    setCopy(true)
    // Remove the textarea from the DOM
    document.body.removeChild(textarea)
    setSnackbarOpen(true)

    setTimeout(() => {
      setCopy(false)
    }, 2000)
  }
  // const handleCopyUrl = () => {
  //   navigator.clipboard.writeText(generatedUrl).then(() => {
  //     setSnackbarOpen(true)
  //   })
  // }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Card
        sx={{
          width: '32%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <MDTypography variant="h4" gutterBottom mb={3}>
          Create Webhook JobNimbus
        </MDTypography>
        <MDBox component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <FormControl fullWidth style={{ marginBottom: '1rem' }}>
            <InputLabel id="event-label">Events</InputLabel>
            <Select
              labelId="event-label"
              name="event"
              value={formData.event}
              onChange={handleChange}
              label="Dropdown 2"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="createcontact">Create Contact</MenuItem>
              <MenuItem value="updatecontact">Update Contact</MenuItem>
              <MenuItem value="deletecontact">Delete Contact</MenuItem>
              <MenuItem value="createjob">Create Job</MenuItem>
              <MenuItem value="updatejob">Update Job</MenuItem>
              <MenuItem value="deletejob">Delete Job</MenuItem>
            </Select>
          </FormControl>

          {/* Submit Button */}
          <div style={{ display: 'flex' }}>
            <Button
              variant="contained"
              type="submit"
              // fullWidth
              style={{ color: 'white', marginTop: '1rem' }}
            >
              Submit
            </Button>
          </div>
        </MDBox>

        {/* Copy URL Button - Show only if URL is generated */}
        {showCopyButton && (
          <>
            {/* <MDTypography variant="body1" sx={{ mt: 2 }}>
              Generated URL: {generatedUrl}
            </MDTypography> */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleCopyUrl}
              sx={{ mt: 2 }}
              fullWidth
              style={{ color: 'white' }}
            >
              Copy URL
            </Button>
          </>
        )}

        {/* Snackbar for Copy Confirmation */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message="URL copied to clipboard"
        />
      </Card>
    </div>
  )
}

export default GoHighLevel

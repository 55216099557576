/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react'

// react-router-dom components
import { Link, useLocation, useParams } from 'react-router-dom'

// @mui material components
import Card from '@mui/material/Card'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

import { useAppServices } from 'hook/services'

function GoHighLevel() {
  const Service = useAppServices()
  const [message, setMessage] = useState()
  const { search } = useLocation()
  const type = new URLSearchParams(search).get('type')
  const { companyId, app_id } = useParams()
  const client_id =
    type === 'free' ? '66058d337f93c5a59eea00f7-lxvxgz42' : '65afa071288655d0204aba41-ltiv2jks'

  const auth_url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=https://app.alltheapps.io/integrations/agency-app&client_id=${client_id}&scope=users.readonly locations.readonly businesses.write calendars.readonly calendars.write calendars/events.readonly calendars/events.write calendars/groups.write calendars/groups.readonly calendars/resources.write conversations.readonly conversations.write conversations/message.write contacts.readonly contacts.write forms.readonly forms.write invoices.readonly invoices.write invoices/schedule.readonly invoices/schedule.write invoices/template.readonly invoices/template.write links.readonly links.write locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly medias.readonly medias.write opportunities.readonly opportunities.write products.readonly products.write products/prices.readonly products/prices.write surveys.readonly users.write workflows.readonly&state=${companyId},${app_id},${type}`

  const hanldeConnect = (e) => {
    e.preventDefault()
    var windowFeatures = 'width=600,height=600,menubar=no,location=no,toolbar=no,scrollbars=yes'

    // Open the URL in a new window with the specified features
    window.open(auth_url, '_blank', windowFeatures)
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        // backgroundImage:
        //   'url("https://cdn.pixabay.com/photo/2016/09/05/15/07/concrete-1646788_1280.jpg")',
        backgroundSize: 'cover',
      }}
    >
      <Card
        sx={{
          width: '35%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        {/* <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          p={2}
          mb={1}
        > */}
        <MDTypography variant="h4" gutterBottom style={{ color: 'black' }}>
          Connect with Agency Actions
        </MDTypography>
        {/* </MDBox> */}

        <MDBox component="form" onSubmit={hanldeConnect} sx={{ mt: 15 }}>
          <MDButton
            type="submit"
            variant="contained"
            color="info"
            fullWidth
            sx={{ mt: 2 }}
            style={{ fontSize: '0.9rem', textTransform: 'capitalize', padding: '0 5rem' }}
          >
            Connect
          </MDButton>

          {/* <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message="Connected Successfully"
            key={vertical + horizontal}
          /> */}
        </MDBox>
      </Card>
    </div>
  )
}

export default GoHighLevel
